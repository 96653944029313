
import { defineComponent, ref } from 'vue';

declare var XLSX:any;
declare var xtos: any ;
declare var stox:any;

import  SerialConnection from "../../components/SerialConnection.vue";

import { Serial ,SerialUsbAutoConnectionDelegate} from "../../modules/hardware/serial";

import { Media } from "../../modules/hardware/media";
import {Geolocation , GeolocationCallback } from "../../modules/hardware/geolocation";
var circleDetectionWorker:any = null;
const piexif = require('piexifjs');

import { faFile } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import AlertSensors from '../alert/AlertSensors.vue';
import { allowedNodeEnvironmentFlags } from 'process';
import * as XLSXModule from 'xlsx';
//import Drawing from 'dxf-writer';


var detectCircleTimeoutID:any =false;
var clearCircleScaleTimerID:any=false;
// const Plugin  = require( "../../modules/app/plugin").Plugin;
// const cv  = require( "../../assets/opencv/opencv.js").cv;

const moment = require('moment');

// declare var cv: any;
// import XSpreadSheet  from "x-data-spreadsheet";

const cv = require('opencv.js');

const hov = 59.9484; //FOV 70 degree
//const hov = 69.84; //FOV 80 degree

const calibrationLongLengthMeterThreshold = 0.5 ;

var circleDetectionSem = false;
// var circles = new cv.Mat();
var selectedCircleNo = 0 ;

const excludeBothLengthMs = 68;

//zzt
let touch_depth = 0;
let touch_theta = 0;
let overlay_radius_i = 0;
let global_canvas: HTMLCanvasElement | null = null;
let savedCanvas: HTMLCanvasElement | null = null;
let dynamicRadiusPixel = 0;
let adjustedDynamicRadiusPixel = 0;
let overlayX = 0;
let overlayY = 0;
let screen_width = 0;

let overlap_screen_width = 0;
let multi_circles = false;
let green_circle = false;
let green_adjust = true;
let overlap_circles: any[] ;
let initial_radius = 0;

let getLaser = 0;
let capturedFrameDataUrl: string | null = null;
let capture_mode = 0;
let capture_touch_depth = 0;
let allOverlapCircles: any[] = []; 
let excelOverlapFile:any = null;
let excelOverlapBlob:any = null;
let longestDepth = 0;
let longestDepthID = -1;



let CircleDetectionSetting :any  = {
    currentNum : 0 ,
    1: {
         dp: 1.1,
         minDist: 40,
         param1 : 45,
         param2: 40,
         minRadius : 30,
         maxRadius : 80
    },
    2: {
         dp: 1.2,
         minDist: 50,
         param1 : 80,
         param2: 45,
         minRadius : 80,
         maxRadius : 190
    },
    3: {
         dp: 1.3,
         minDist: 70,
         param1 : 60,
         param2: 50,
         minRadius : 190,  
         maxRadius : 400
    }
    /*,
    3: {
         dp: 0.9,
         minDist: 15,  //increae-> reduce nearby cycle to detect
         param1 : 55,  //decrease-> detect more edge, 50> detect only selective edges, 
         param2: 20,
         minRadius : 190,
         maxRadius : 350
    } */
    
};

/*
let CircleDetectionSetting :any  = {
    currentNum : 2 ,
    1: {
         dp: 1,
         minDist: 15,
         param1 : 50,
         param2: 20,
         minRadius : 25,
         maxRadius : 55
    },
    2: {
         dp: 1,
         minDist: 25,
         param1 : 50,
         param2: 20,
         minRadius : 70,
         maxRadius : 110
    },
    3: {
         dp: 1,
         minDist: 50,
         param1 : 50,
         param2: 20,
         minRadius : 95,
         maxRadius : 150
    } 
    
};

*/

function downloadBase64Image(linkSource:string, fileName:string) {
     // const linkSource = `data:${contentType};base64,${base64Data}`;
     const downloadLink:any = document.createElement("a");
     downloadLink.href = linkSource;
     downloadLink.download = fileName;
     downloadLink.click();
}
    
function degToDmsRational(degFloat :any ) {
    var minFloat = degFloat % 1 * 60
    var secFloat = minFloat % 1 * 60
    var deg = Math.floor(degFloat)
    var min = Math.floor(minFloat)
    var sec = Math.round(secFloat * 100)

    deg = Math.abs(deg) * 1
    min = Math.abs(min) * 1
    sec = Math.abs(sec) * 1
  
    return [[deg, 1], [min, 1], [sec, 100]]
  }

function convertSensorDataToArr (tex:any){
        
        const arr = [];
        const texs =tex.split('\r\n');
        for ( var i=0 ; i < texs.length; i++ ){
                    const text = texs[i];
            
                    if(text.indexOf('E') !== -1) { continue; }    
               
                    const result = text.match(/D=(.*)m,/);
                 
                    if (result != null && result.length != 0 ) {  
        
                        arr.push( Number(result[1]) );
                    }
                  }
             return arr;
}

       // cv.HoughCircles(gray,circles,cv.HOUGH_GRADIENT,1,20,  50, 30,40,80);
const white = new cv.Scalar(255, 225, 255,100);
const nextCirclecol = new cv.Scalar(255, 174, 185,100);  //zzt for overlap circle
const green = new cv.Scalar(100, 225, 205,100);

interface ICalibration {
  serial1Value:      number;
  serial2Value:      number;
  serial3Value:      number;
  add:      Function;
  ratio : Function;
}
 

const updateCanvas = document.createElement('canvas');
const updateSubCanvas = document.createElement('canvas');

export default {
  name: "BoreholeApp" ,
  computed: {
    undoButtonClass(this:any):any {
      return {
        'btn btn-dark': this.canBeUndone,
        'btn btn-secondary': !this.canBeUndone, // グレーの場合に btn-secondary を使用
      };
    },
  },
  data(): any {
          return {
            canBeUndone : false ,
            selectedCircleDetectionMode: CircleDetectionSetting.currentNum,
            skipWindowRate: 30, 
             detectionModeList : [
              
              { value : "length" , title: "削孔長" },
              { value : "circle,length" , title: "削孔径,削孔長" } 
              ] ,
           selectedDetectionMode:  "circle,length" ,
            circleImage : new Image() ,
            
            measurementTimerMs : 2000,
            detectCircleIntervalMs : 1000,  
            nonOverlapCircleAreaEnabled : false , 
            isLoading : false,
            realData1 : 0,
            realData2 : 0,
            realData3 : 0,
            
            scaleRealWorldDiameter: 4.0 ,
            isGpsUsed :false,
            showApp :0 ,
            clearConnection:false,
            selectedSubCameraDevice : undefined,
            selectedMainCameraDevice : undefined,
            useSubCamera:false,
        cameraDeviceIds : [] ,
        denshiShoKokuban : {
            builder : "" ,
            projectName : "", 
            projectType : "" ,
            site :"",
            status : "" ,
            isNecessary : true 
        },
              hasCorrectionFile : false , 
              isMeasuring : false ,
              isMeasurementStopped : false,          
              recentlyAcquiredData : [ ] ,
              recentlyAcquiredDataMax : 20 ,
  
             // acquiredData : [  ] ,
              directoryHandle : false , 
              enableDiameter : false , 
              isDebugMode : false , 
               debugModeCount : 0 ,
               calibrationA : -8.97142857,
               calibrationB:  1.202857143,
              // calibrationLongLengthMeter : 0.544 , 
                 calibrationLongLengthMeter : 0.750, 
               calibrationShortLengthMeter  : 0.088 ,
    
         testSettingVisble  : false,
         testCircleDetectionSetting : {
         dp: 1,
         minDist: 20,
         param1 : 50,
         param2: 30,
         minRadius : 40,
         maxRadius : 80
         },
         
          geolocation : undefined , 
          gps : { lat : 0 , lon : 0},
          calibrationThresholdShortMeasurementsCentimeter : 65 ,
          calibrationCorrectionShortMeasurementsMillimetre : 9 ,
          
          calibration : {
            isLowerThanBothLengthChecked : true ,
            use : "add",
            serial1Value : 0,
            serial2Value : 0,
            serial3Value : 0
          } as any ,
       
        objectDetectingSetting : { area : { y : { top : 0.2, bottom: 0.25  } ,  x : { left : 0.2, right: 0.2  }  }},
         faFile :faFile,
        objectDetectingPoint : { start : { x:0 ,y:0 } , end : { x:0,y:0} } ,
        serialIntervalTaskFunc : undefined ,
        filename : "" , 
        
       SpreadSheetKey : 0 , 
       avgLength : 0,
       fullLength : 0 ,
       touchLength : 0 ,  //laser to surface distance
        nonOverlapCircleArea : 0 ,
       diameter : 0 ,  
       DiameterCorrectionValue : 
       { detectStartPoint : 68  , coefficient :  1   },
         LengthCorrectionValue : {
            side: { sub: 0 } ,
            center : { thr : 830 , add : { above: 0 ,  below: 0 } }
        },
        cameraRate:  { ideal: 10, max: 14},
       selectCircleMode : false , 
       detectCircleIntervalID : undefined , 
       detectCircleIntervalClear : false ,
       
       currentCircle : { center: 0 , radius: 0 } , 
        circles : undefined , 
       serial1Value: 0 ,
       serial2Value: 0 ,
       serial3Value: 0 ,
       
       serial1Error : "",
       serial2Error : "",
       serial3Error : "",
       
         viewState:  {
            isCanvasShow :false,
            isExcelShow :true,
            isSettingShow :false,
            isExcelFileMenuShow : false,
            isExcelSelectCellMenuShow:false,
            selectCellMode : false ,
            fullLengthCellSelected : false ,
            diameterCellSelected: false ,
            gpsCellSelected : false,
            DateTimeCellSelected:false,
            RealData1CellSelected:false,
            RealData2CellSelected:false,
            RealData3CellSelected:false,
            laserDistanceCellSelected:false,
            YValueCellSelected:false,
            }, 
         cameraInput :undefined,
         cameraOutput: undefined ,
         serial1 : undefined ,
         serial2 : undefined ,
         serial3 : undefined ,
         xlsxUpdated : false , 

         spreadsheet : null ,
         xSpreadSheetOption : { 
             showToolbar: false,
             showGrid: true,
                showContextmenu: false,
            },  
         xSpreadSheetData :[{}] ,
        
         selectedFullLengthCell: { 'row': -1 , 'col': -1 },
         selectedDiameterCell: { 'row': -1 , 'col': -1 },
         selectedGPSCell: { 'row': -1 , 'col': -1 },
        
         selectedDateTimeCell: { 'row': -1 , 'col': -1 },
         selectedRealData1Cell: { 'row': -1 , 'col': -1 },
         selectedRealData2Cell: { 'row': -1 , 'col': -1 },
         selectedRealData3Cell: { 'row': -1 , 'col': -1 },


         media1 : undefined ,
         mediaSubCamera : undefined,
         videoWidth:0,
         videoHeight:0 ,
         colWidth : 0 ,
         rowHeight : 0,
         
         canvas2dCtx : undefined,
         presetVideo: undefined ,
         previousFocusPosition : {x:0,y:0},
         isCaptureDisabled: false,
         isCaptureActive: false,
         baseLineA: null, // Initial value for the first text box
         baseLineB: null,
         baseLineY: null,
         regressionData: [],

         
      } //return (data)

  }, //data()
  components:  { SerialConnection } ,
  
  watch: {
    viewState: {
      async handler(this:any,newVal:any) {

        if (this.viewState.isCanvasShow) {
        
          this.showLoadingView();
          this.$nextTick(async () => {
         
            this.removeExcel(); 
            await this.startMainCamera();
            await this.startSubCamera();
           
            this.isLoading=false;
          });
             // await this.$refs.cameraConnection.open();  
        }
        if(this.viewState.isSettingShow ){
          
          this.showLoadingView();
          this.$nextTick(async () => {
              
              await this.connectSerial();
              this.isLoading=false;
          });
        }
        if (this.viewState.isExcelShow) {
          
          this.showLoadingView();
          this.$nextTick(() => {
                
                this.createExcel(); 

                this.isLoading=false;
              // this.startMainCamera();
            // ここで必要な処理を実行
          });
        }
      
      },
      deep: true
    }
  },
  beforeUnmount(this: any ) {
  
    this.closeSerial();
    (window as any).document.removeEventListener('focusin', this.handleFocusEvent );
 
   /*
 console.log("beforeUnmount");
        window.removeEventListener('message', this.outsideClickEvent);

    var spreadsheetArea = document.getElementById("spreadsheet-area");
    var xSpreadsheet = document.getElementById("x-spreadsheet");
    if (xSpreadsheet) {
      if(spreadsheetArea){
        spreadsheetArea.removeChild(xSpreadsheet);
      }
    }
*/

  },

async mounted (this:any){
    //alert("mounted");
   
    (window as any).document.addEventListener('focusin', this.handleFocusEvent   , true); 

    // Ensure canvas and other elements are ready
    this.canvas2dCtx = this.$refs.canvas.getContext("2d");
    this.cameraInput = this.$refs.canvas;
    this.cameraOutput = this.$refs.canvasOutput;
    this.dispCanvas = this.$refs.dispCanvas;

     if ( this.$route.query.show == "canvas" ){

      this.$nextTick( () => {
           setTimeout(async ()=>{
           await this.showArea("Canvas");   
           },1500);
        });   
      }
      this.createExcel(); 
        
    
      await this.initSettings();
      
      if( this.isMeasuring) { 
            await this.connectSerial();
            await this.measurement();
      }

      //to keep excel data, and excel file 
      await this.loadOverlapCirclesFromStorage();
      await this.loadLatestExcelFileFromIndexedDB();
    
    
  }, //mounted()
  beforeMount :async function( this: any ){

       //  console.log("beforeMount");
this.circleImage =
      await (async (src:any)=> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    img.src = src;
  })})("/pwa/img/overlay_circle.png");

  this.alternateCircleRed={} as any;
/* 
 this.alternateCircleRed = await (async (src:any) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = (e) => reject(e);
            img.src = src;
        });
    })("/pwa/img/alternate_red.png");   
*/
  this.initCircleDetectionWorker();
  }
  ,updated(this:any){
    if ( this.xlsxUpdated ) {
        this.setXlsxFunc();
        this.xlsxUpdated = false ;
    }
  }
  , created(){} 
  ,methods:{

  moveCircle(this:any, direction:any) {

    // Adjust the x or y position based on the 
    if(!multi_circles){
      // Define the movement amount
      const movementAmount = 5; // You can adjust this to move more or less per click

      // Adjust the x or y position based on the direction
      switch (direction) {
        case 'up':
          overlayY -= movementAmount;
          break;
        case 'down':
          overlayY += movementAmount; 
          break;
        case 'left':
          overlayX -= movementAmount; 
          break;
        case 'right':
          overlayX += movementAmount; 
          break;
      }//switch
    }//if(!multi_circles)

    if(multi_circles){
      //clear green-circle canvas first
      const dispCircleCtx =   this.$refs.dispCircleCanvas.getContext('2d');
      if (dispCircleCtx) {
        // Clear the entire canvas
        dispCircleCtx.clearRect(0, 0, this.$refs.dispCircleCanvas.width, this.$refs.dispCircleCanvas.height);
      } else {
        console.error("Failed to get the 2D context of the dispCircleCanvas");
      }


      if (overlap_circles.length === 0) return;
      const movementAmount = 2;
      const current_index = overlap_circles.length - 1;  //pick up the latest circle from overlap_circles
      switch (direction) {
        case 'up':
          overlap_circles[current_index].y -= movementAmount;
          break;
        case 'down':
          overlap_circles[current_index].y += movementAmount; // Move down (increase y)
          break;
        case 'left':
          overlap_circles[current_index].x -= movementAmount; // Move left (reduce x)
          break;
        case 'right':
          overlap_circles[current_index].x += movementAmount; // Move left (reduce x)
          break;
      }
      //calculate related data from the current updated circle
      const push_no = 0;
      const move = 1;
      this.calculateNonOverlapAreaMultiCircles(overlap_circles[current_index].radius, overlap_circles[current_index].x, overlap_circles[current_index].y, push_no, move, 0);
    } //if(multi_circles)

    this.updateCirclePosition();
  }, //moveCircle

  updateCirclePosition(this:any) {
    // Clear and redraw the circles with the updated position
    let canvas = global_canvas;  // Assuming global_canvas is defined and available
    if(green_adjust){
      const dispCircleCanvas = this.$refs.dispCircleCanvas;
      const dispCircleCtx = dispCircleCanvas?.getContext('2d');
      if (dispCircleCtx) {
        // Clear the entire canvas
        dispCircleCtx.clearRect(0, 0, this.$refs.dispCircleCanvas.width, this.$refs.dispCircleCanvas.height);
      } else {
        console.error("Failed to get the 2D context of the dispCircleCanvas");
      }
      canvas = dispCircleCanvas;
      //canvas = this.$refs.canvasOutput ;
    }
      if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          if(!multi_circles){
            if(canvas){
              this.drawCircleImage(overlayX, overlayY, dynamicRadiusPixel, canvas, 0, 0, 0, 0);
            }
          }//if(!multi_circles)
          if(multi_circles){
            //ctx.clearRect(0, 0, canvas.width, canvas.height);
            //draw previous frame
            //......................................................
            if (!capturedFrameDataUrl) {
                console.error("No captured frame available.");
                return;
            }   
            const img = new Image();
            img.src = capturedFrameDataUrl; 
            img.onload = () => {
              if (ctx && canvas) {
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                overlap_circles.forEach((circle, index) => {
                if (canvas) {
                  const largerID = -1;
                  this.drawCircleImage(circle.x, circle.y, circle.radius, canvas, index, 0, 0, 0);
                }
                });
              }//if
            };
          } //if(multi_circles)
        } //if (ctx)
    }//if (canvas) 
  }, //updateCirclePosition
  
  setOverlayRadius(this:any, value:any) {

    //overlay_radius_i = multi_circles ? value : Math.sign(value) * 10;
    overlay_radius_i = value;
    if (overlay_radius_i !== 0) {   // Increase or decrease the dynamic radius 
        if (Math.sign(overlay_radius_i) === 1) {  // Positive (up button pressed)
          if(!multi_circles){
            overlay_radius_i += 7;
          }
          dynamicRadiusPixel += overlay_radius_i;  // Increase radius
        } else {  // Negative (down button pressed)
          if(!multi_circles){
            overlay_radius_i -= 7;
          }
          dynamicRadiusPixel -= Math.abs(overlay_radius_i);  // Decrease radius
        }
    }//if
    

    if (!multi_circles) {
        if(green_adjust){
          const dispCircleCanvas = this.$refs.dispCircleCanvas;
          const dispCircleCtx = dispCircleCanvas?.getContext('2d');
          if (dispCircleCtx) {
            dispCircleCtx.clearRect(0, 0, this.$refs.dispCircleCanvas.width, this.$refs.dispCircleCanvas.height);
          } else {
            console.error("Failed to get the 2D context of the dispCircleCanvas");
          }
          global_canvas = dispCircleCanvas;
          //global_canvas = this.$refs.canvasOutput
        }
        if (global_canvas) {
            adjustedDynamicRadiusPixel = dynamicRadiusPixel;
            this.drawCircleImage(overlayX, overlayY, adjustedDynamicRadiusPixel, global_canvas, 0, 0, 0, 0);
        }
    }

      // Find the circle that matches the `overlayX` and `overlayY` coordinates
    if(multi_circles) {
      //clear green-circle canvas first
      if(!green_circle){
        const dispCircleCtx =   this.$refs.dispCircleCanvas.getContext('2d');
        if (dispCircleCtx) {
          // Clear the entire canvas
          dispCircleCtx.clearRect(0, 0, this.$refs.dispCircleCanvas.width, this.$refs.dispCircleCanvas.height);
        } else {
          console.error("Failed to get the 2D context of the dispCircleCanvas");
        }
      }

      for (let i = 0; i < overlap_circles.length; i++) {
        if (overlap_circles[i].x === overlayX && overlap_circles[i].y === overlayY) {
            // Update the radius for the matched circle
            overlap_circles[i].radius = dynamicRadiusPixel;
            adjustedDynamicRadiusPixel =  dynamicRadiusPixel;

            //update non-overlap area of adjusted area
            const push_no = 0;
            const move = 1;
            this.calculateNonOverlapAreaMultiCircles(adjustedDynamicRadiusPixel, overlayX, overlayY, push_no, move,0);
            
            // Redraw all circles without clearing the canvas
            if (global_canvas) {
              const canvas = global_canvas;  // Assuming global_canvas is defined
              if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                  ctx.clearRect(0, 0, canvas.width, canvas.height);
                  //draw previous frame
                  //......................................................
                  if (!capturedFrameDataUrl) {
                      console.error("No captured frame available.");
                      return;
                  }   
                  const img = new Image();
                  img.src = capturedFrameDataUrl; 
                  img.onload = () => {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    overlap_circles.forEach((circle, index) => {
                        this.drawCircleImage(circle.x, circle.y, circle.radius, canvas, index, 0, 0, 0);
                    });
                  };
                  //......................................................
                }//if (ctx)
              } //if (canvas)
            } //if (global_canvas)
            break;
        } //inner-if
      }//for
    }
  }, //setOverlayRadius(value:any)

  async clearAllCircles(this:any) {

      green_circle == false ;
      multi_circles = false;

      //clear the drawed canvas
      let canvas = global_canvas;  // Assuming global_canvas is defined and available
      if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear the entire canvas
        }
      }
      global_canvas = null;

      //clear variables
      overlap_circles.splice(0, overlap_circles.length); //clear overlap_circles[]
      dynamicRadiusPixel = 0;  // Reset radius if needed 
      overlayX = 0;  // Reset X position
      overlayY = 0;  // Reset Y position
    
      //overlap_screen_width = window.innerWidth;
      //initial_radius = overlap_screen_width / 10.0;
      getLaser = 0;
      this.isCaptureActive = false; // Mark as displaying a captured frame
      this.isCaptureDisabled = false; // Dim the button
      initial_radius = overlap_screen_width / 10.0;
      longestDepth = 0;
      //alert("initial " + initial_radius);
      //this.isMeasuring = true ; //set true to save in excel(merge-code with green)
      
  }, //clearAllCircles()

  async undoCircle(this: any) {

    // Clear the canvas
    if (global_canvas) {
        const canvas = global_canvas;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                //multi-circles
                if(multi_circles && !green_circle){
                  if (overlap_circles.length === 0) {
                      console.warn("No circles to undo.");
                      return;
                  }
                  // Remove the latest circle from the overlap_circles array
                  overlap_circles.pop();
                  // Redraw the captured frame 
                  if (capturedFrameDataUrl) {
                      const img = new Image();
                      img.src = capturedFrameDataUrl;
                      img.onload = () => {
                          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                          // Redraw all remaining circles
                          overlap_circles.forEach((circle, index) => {
                              this.drawCircleImage(circle.x, circle.y, circle.radius, canvas, index, 0, 0, 0);
                          });
                      };
                  } else {
                      console.error("No captured frame available.");
                  }
              }//if(multi_circles)
              
              //green_cricle
              if(green_circle && !multi_circles){
                global_canvas = null;
              }//green_circle

            } //if (ctx) {
        }
    } else {
        console.error("No canvas available.");
    }
},//undoCircle()

async getLaserDepth(this:any) {
    //enable camera
    capture_mode = 0;
    this.isCaptureDisabled = false;
    this.isCaptureActive = false;
    getLaser = 1;
    //redraw all-circles on live canvas
    if (global_canvas) {
      const canvas = global_canvas;  // Assuming global_canvas is defined
      if (canvas) {
          const ctx = canvas.getContext('2d');
          if (ctx) {
          
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          }
      }
      for (let i = 0; i < overlap_circles.length; i++) {
          overlap_circles[i].prevNonOverlapArea = overlap_circles[i].nonOverlapArea;  
      }//for



      if(overlap_circles.length > 0) {
          //redraw all circles
          overlap_circles.forEach((circle, index) => {
          this.drawCircleImage(circle.x, circle.y, circle.radius, canvas, index, 0, 0, 0);
          });
      }//if
    } //if (global_canvas)
  },

  isTouchInsideCircle(this:any, touchX: number, touchY:number, canvas:any) {
    /*
    for (let i = 0; i < overlap_circles.length; i++) {
        const circle = overlap_circles[i];
        const distance = Math.sqrt(Math.pow(touchX - circle.x, 2) + Math.pow(touchY - circle.y, 2));
        const radius = circle.radius * 0.5 ; //*** as the real-radius is two time, reduce half to get canvas ratio
        if (distance <= radius) {
          overlap_circles[i].prevNonOverlapArea = overlap_circles[i].nonOverlapArea;
        }
    }//for
    */
    for (let i = 0; i < overlap_circles.length; i++) {

        //alert("i " + i);
        const circle = overlap_circles[i];
        //confirm which circle is touching...
        const distance = Math.sqrt(Math.pow(touchX - circle.x, 2) + Math.pow(touchY - circle.y, 2));
        const radius = circle.radius * 0.5 ; //*** as the real-radius is two time, reduce half to get canvas ratio
        if (distance <= radius) {
            //compare with previous-circle
            const fromCircle = overlap_circles[i].fromCircle;
            const parts = fromCircle.split("-"); // Split the string by the "-"
            const preCirId = parts[0].substring(1); // Remove the "c" from "c1"
            const currentCirId = parts[1].substring(1); // Remove the "c" from "c2" 

            //alert("index " + i + " distance " + distance + " circle.radius " + circle.radius);
            overlap_circles[i].full_depth = this.fullLength; // Update full_depth
            //overlap_circles[i].full_depth =  Math.floor(Math.random() * 100); //dummy
            overlap_circles[i].touchX = touchX;
            overlap_circles[i].touchY = touchY;
                //calculate specific partil-area with prev-circle
                const cen_to_cenMM = overlap_circles[i].cen_to_cenMM;

                if(cen_to_cenMM > 0){
                if(overlap_circles[i].full_depth > overlap_circles[preCirId-1].full_depth){
                  overlap_circles[i].nonOverlapArea += overlap_circles[i].relatedOverlapArea;
                }else{
                  overlap_circles[preCirId-1].nonOverlapArea += overlap_circles[i].relatedOverlapArea;
                }
              }//if(cen_to_cenMM > 0)
            //overlap_circles[i].nonOverlapVolume = overlap_circles[i].nonOverlapArea * overlap_circles[i].full_depth; 
          //redraw circles 
              if (canvas) {
                  const ctx = canvas.getContext('2d');
                  if (ctx) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                  }
              }
              if(overlap_circles.length > 0) {
                  //redraw all circles
                  overlap_circles.forEach((circle, index) => {
                  this.drawCircleImage(circle.x, circle.y, circle.radius, canvas, index, circle.touchX, circle.touchY, circle.full_depth);
                  });
              }//if

            //alert(`located inside circle ${i} with depth: ${circle.full_depth}`);
            break;
        }//if
        
    } //for
  }, //isTouchInsideCircle 

  async captureTarget(this:any) {
    // Reference to the video element (Main Camera)

    multi_circles = true ;
    green_circle  = false ;
    overlap_circles = [];

    // Clear the green-circle canvas
    const canvasOutput = this.$refs.canvasOutput;
    if (canvasOutput) {
        const ctx = canvasOutput.getContext('2d');
        if (ctx) {
            ctx.clearRect(0, 0, canvasOutput.width, canvasOutput.height);
            console.log("Canvas cleared.");
        } else {
            console.error("Failed to get 2D context of canvasOutput.");
        }
    } else {
        console.error("Canvas output reference not found.");
    }

    //get initial laser depth
    capture_mode = 1;
    const LaserStartingPoint =  this.DiameterCorrectionValue.detectStartPoint ;
    
    capture_touch_depth = (  ( this.avgLength - this. calibrationA ) /this. calibrationB  ) + LaserStartingPoint  ; 
    this.touchLength = capture_touch_depth ;
    //alert("capture_touch_depth " + capture_touch_depth);

    const videoElement = this.$refs.videoMainCamera;
    if (!videoElement || videoElement.readyState < 2) {
        console.error("Video element is not ready.");
        return;
    }

    //get the common canvas to draw
    const canvas = this.$refs.dispOverlayCircleCanvas;
    const context = canvas.getContext('2d');

    //draw captured frame on canvas
    if (context && !this.isCaptureDisabled) {
      context.drawImage(
            videoElement,
            0,0,  
            videoElement.videoWidth, videoElement.videoHeight,  // Crop width and height
            0, 0,  // Destination X, Y on the canvas
            canvas.width, canvas.height   // Destination width and height on the canvas
        );
      //keep to draw current frame when overlay
      capturedFrameDataUrl = canvas.toDataURL('image/png'); 
      this.isCaptureActive = true; // Mark as displaying a captured frame
      this.isCaptureDisabled = true; // Dim the button

      //save for later use
      savedCanvas = document.createElement('canvas');
      savedCanvas.width = canvas.width;
      savedCanvas.height = canvas.height;

      const savedCtx = savedCanvas.getContext('2d');
      if (savedCtx) {
          savedCtx.drawImage(canvas, 0, 0); // Copy content of global_canvas to savedCanvas
      }

    } else {
      alert("Failed to get 2D context from canvas or capture is already active.");
      return;
    }

    //from clearCircle
    overlap_circles.splice(0, overlap_circles.length);
    dynamicRadiusPixel = 0;  // Reset radius if needed 

    overlayX = 0;  // Reset X position
    overlayY = 0;  // Reset Y position
    
    overlap_screen_width = window.innerWidth;
    initial_radius = overlap_screen_width / 10.0;
    getLaser = 0;
    
}, //captureTarget
async switchToGreen(this:any){
  green_adjust = true;
  green_circle = true;
},
async downloadLatestExcelFile() {

  if (!excelOverlapFile) {
    alert("ダウンロード可能なファイルがありません。"); // "No file available for download."
    return;
  }
  try {
    this.downloadFileLocally(excelOverlapBlob, excelOverlapFile);

    // Clear persistent overlap_data after download
    await this.clearOverlapDataFromStorage();
    
  } catch (error) {
    console.error("Error during download:", error);
    alert("エラーが発生しました。ダウンロードに失敗しました。"); // "An error occurred. Download failed."
  }
},

downloadFileLocally(
  blob: Blob,
  fileName: string,
  optionalFileData?: { dataUrl: string, fileName: string }
) {
  //alert("comes to download");
  // Download the main file (e.g., Excel)
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();

  // If there's an optional file (e.g., canvas image), download it
  if (optionalFileData?.dataUrl) {
    const imageLink = document.createElement("a");
    imageLink.href = optionalFileData.dataUrl;
    imageLink.download = optionalFileData.fileName;
    imageLink.click();
  }
},

async clearOverlapDataFromStorage() {
  const db = await this.openIndexedDB();
  if (!db) return;

  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction("overlap_data", "readwrite");
    const store = transaction.objectStore("overlap_data");
    const clearRequest = store.clear();

    clearRequest.onsuccess = () => {
      //alert("All overlap data cleared from storage.");
      allOverlapCircles = []; // Reset in-memory storage
      resolve();
    };

    clearRequest.onerror = (event: any) => {
      console.error("Failed to clear overlap data:", event);
      reject("Error clearing overlap data.");
    };
  });
},
async saveCombinedCanvas(this:any, dispCircleCanvas:HTMLCanvasElement, global_canvas: HTMLCanvasElement) {
  // Step 1: Create a new canvas with the same dimensions as the background canvas
  const combinedCanvas = document.createElement("canvas");
  combinedCanvas.width = dispCircleCanvas.width;
  combinedCanvas.height = dispCircleCanvas.height;
  const combineCtx = combinedCanvas?.getContext('2d');
  if (!combineCtx) {
    console.error("Failed to get 2D context for combined canvas.");
    return;
  }

  if (savedCanvas) {
    combineCtx.drawImage(savedCanvas, 0, 0);
  }
  // Step 2: Draw the background canvas onto the combined canvas
  if (dispCircleCanvas) {
    combineCtx.drawImage(dispCircleCanvas, 0, 0);
  }
  // Step 3: Draw the overlay canvas on top of the background canvas
  if(global_canvas) {
    combineCtx.drawImage(global_canvas, 0, 0);
  }

  // Step 4: Save the combined canvas as an image
  const imageData = combinedCanvas.toDataURL("image/png");
  const formattedDate = new Date().toISOString().split("T")[0]; // e.g., "2024-12-12"
  const formattedTime = new Date().toLocaleTimeString().replace(/:/g, "-"); // e.g., "10-30-45"
  const imageFileName = `red_circles-${formattedDate}-${formattedTime}.png` || "default-filename.png";

  // Save locally (example method)
  this.downloadOverlapImageLocally(imageData, imageFileName);
},

async saveOverlapDataToDXF(this: any, overlap_circles: any) {
  // DXF Header Section
  const header = `0\nSECTION\n2\nHEADER\n0\nENDSEC\n0\nSECTION\n2\nENTITIES\n`;

  // DXF Footer Section
  const footer = `0\nENDSEC\n0\nEOF\n`;

  // Initialize DXF entities (circles and text labels)
  let entities = '';

  overlap_circles.forEach((circle: any, index: number) => {
    // Calculate real-world center and radius
    const realWorldRadius = circle.diameterMM / 2.0 ;

    let x_real = circle.xReal;
    let y_real = circle.yReal;

    //draw circle
    entities += `0\nCIRCLE\n8\nCIRCLES\n62\n1\n10\n${x_real}\n20\n${-y_real}\n30\n0\n40\n${realWorldRadius}\n`;
    //add center point
    entities += `0\nCIRCLE\n8\nCIRCLES\n62\n1\n10\n${x_real}\n20\n${-y_real}\n30\n0\n40\n0.5\n`;
    //add ID
    entities += `0\nTEXT\n8\nCIRCLES\n62\n3\n10\n${x_real}\n20\n${-y_real}\n30\n0\n40\n2.0\n1\nID-${circle.circleId}\n`;

  });

  // Combine the DXF content
  const dxfContent = header + entities + footer;

  // Save the DXF file
  const formattedDate = new Date().toISOString().split("T")[0]; // e.g., "2024-12-12"
  const formattedTime = new Date().toLocaleTimeString().replace(/:/g, "-"); // e.g., "10-30-45"
  const blob = new Blob([dxfContent], { type: 'application/dxf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `red_circles-${formattedDate}-${formattedTime}.dxf`;
  link.click();
},

async saveOverlapDataToExcel(this: any) {

  //generate dxf file
  this.saveOverlapDataToDXF(overlap_circles);

  //find-volume, longest-depth
  let longestFullDepth = -Infinity; // Initialize to a very small value
  let longestCircleIndex = -1;  
  overlap_circles.forEach((circle: any,i: number) => {
    overlap_circles[i].nonOverlapVolume = overlap_circles[i].nonOverlapArea * overlap_circles[i].full_depth; 

    if (circle.full_depth > longestFullDepth) {
        longestFullDepth = circle.full_depth;
        longestCircleIndex = i; // Store the index of the largest circle
    }
  });
  longestDepth = longestFullDepth;
  longestDepthID = longestCircleIndex;

  if (global_canvas) {
      const ctx = global_canvas.getContext('2d');
      if (ctx) {
          ctx.clearRect(0, 0, global_canvas.width, global_canvas.height);
      }
  }
  overlap_circles.forEach((circle: any, i: number) => {
    this.drawCircleImage(circle.x, circle.y, circle.radius, global_canvas, i, circle.touchX, circle.touchY, circle.full_depth);


  });

  //find the longest-depth circle, and color 
  // Add current overlap_circles to global storage
  //allOverlapCircles = [...allOverlapCircles, ...overlap_circles];
  allOverlapCircles = [...allOverlapCircles, [...overlap_circles]]; // Nested arrays: each `overlap_circles` is a separate row

  await this.saveOverlapCirclesToStorage(); // Save to IndexedDB

  // Alert if there's no data to save
  if (allOverlapCircles.length === 0) {
    alert("データがありません。保存できません。"); // "No data to save" in Japanese
    return;
  }

   // File name formatting
  const now = new Date();
  const formattedDate = `${now.getFullYear()}年${now.getMonth() + 1}月${now.getDate()}日`;
  const formattedTime = `${now.getHours()}時${now.getMinutes()}分${now.getSeconds()}秒`;
  excelOverlapFile = `非重複面積-${formattedDate}-${formattedTime}.xlsx`;
  
  // Capture the canvas images as a data URL
  const dispCircleCanvas = this.$refs.dispCircleCanvas;
  const dispCircleCtx = dispCircleCanvas?.getContext('2d');
  const canvas = global_canvas; 
  let imageData, imageFileName;
  if (canvas) {
    this.saveCombinedCanvas(dispCircleCanvas, global_canvas);
  }

  // Prepare data for Excel
  const formattedDateTime = `${now.getFullYear()}/${String(now.getMonth() + 1).padStart(2, '0')}/${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
  
  // Create rows where each `overlap_circles` array is one row
  const newData = allOverlapCircles.map((circleGroup: any[], groupIndex: number) => {
    // Start with fixed columns
    const row: any = {
      番号: `${groupIndex + 1}`,
      日時: formattedDateTime,
    };
    
    // Dynamically add data for each circle in the group
    circleGroup.forEach((circle, index) => {
      const prefix = `C${index + 1}`;
      row[`${prefix}_ID`] = `ID-${circle.circleId}`;
      row[`${prefix}_C-C`] = `${circle.fromCircle}`;
      row[`${prefix}_中心間距離 (mm)`] = circle.cen_to_cenMM;
      row[`${prefix}_直径 (mm)`] = circle.diameterMM;
      row[`${prefix}_特定重なり面積 (mm)`] = circle.relatedOverlapArea;
      row[`${prefix}_総重なり面積 (mm)`] = circle.partialOverlapArea;
      row[`${prefix}_古い非重複面積 (mm²)`] = circle.prevNonOverlapArea;
      row[`${prefix}_非重複面積 (mm²)`] = circle.nonOverlapArea;
      row[`${prefix}_削孔長 (mm)`] = circle.full_depth;
      row[`${prefix}_体積 (mm³)`] = circle.nonOverlapVolume;
      //row[`${prefix}_baseLineY`] = circle.baseLineY;
    });

    return row;
  });

  try {
    let workbook: XLSXModule.WorkBook;
    let worksheet: XLSXModule.WorkSheet;

      // Create a new workbook and worksheet with the full data
      workbook = XLSXModule.utils.book_new();
      worksheet = XLSXModule.utils.json_to_sheet(newData);

      //set Bold to ID
      XLSXModule.utils.book_append_sheet(workbook, worksheet, "データ");

      // Convert workbook to Blob
      const excelBuffer = XLSXModule.write(workbook, { bookType: "xlsx", type: "array" });
      excelOverlapBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    
      //alert("データが保存されました。"); // Data saved successfully
  } catch (error) {
      console.error("Error saving Excel file:", error);
      alert("エラーが発生しました。データを保存できませんでした。"); // Error message
  }
},
// Save overlap_circles persistently (optional, for data reuse after page reload)
async saveOverlapCirclesToStorage() {
  //alert("comes to save");
  try {
    const db = await this.openIndexedDB();
    if (!db) throw new Error("Failed to open IndexedDB");

    return new Promise<void>((resolve, reject) => {
      const transaction = db.transaction("overlap_data", "readwrite");
      const store = transaction.objectStore("overlap_data");
      store.put({ id: "overlap_circles", data: allOverlapCircles }); // Save data with a fixed key

      transaction.oncomplete = () => {
        console.log("Data successfully saved to IndexedDB.");
        resolve();
      };

      transaction.onerror = (event:any) => {
        console.error("Failed to save data:", event);
        reject("Error saving to IndexedDB");
      };
    });
  } catch (error) {
    console.error("saveOverlapCirclesToStorage Error:", error);
  }
},

// Load overlap_circles from persistent storage (e.g., IndexedDB)
async loadOverlapCirclesFromStorage(): Promise<any[]> {
  const db = await this.openIndexedDB();
  if (!db) return [];

  return new Promise<any[]>((resolve, reject) => {
    const transaction = db.transaction("overlap_data", "readonly");
    const store = transaction.objectStore("overlap_data");
    const getRequest = store.get("overlap_circles");

    getRequest.onsuccess = () => {
      allOverlapCircles = getRequest.result?.data || [];
      resolve(allOverlapCircles);
    };
    getRequest.onerror = () => {
      reject("Failed to load overlap_circles.");
    };
  });
},
async loadLatestExcelFileFromIndexedDB(): Promise<{ fileName: string; file: Blob } | null> {
  const db = await this.openIndexedDB();
  if (!db) return null;

  return new Promise<{ fileName: string; file: Blob } | null>((resolve, reject) => {
    const transaction = db.transaction("files", "readonly");
    const store = transaction.objectStore("files");

    const getAllRequest = store.getAll();

    getAllRequest.onsuccess = () => {
      const files = getAllRequest.result;
      if (files.length > 0) {
        // Assuming the last file saved is the most recent one
        const latestFile = files[files.length - 1];
        excelOverlapFile = latestFile.fileName; // Update the global file name
        excelOverlapBlob = latestFile.file; // Update the global Blob
        resolve(latestFile);
      } else {
        resolve(null); // No files found
      }
    };

    getAllRequest.onerror = (event: any) => {
      console.error("Failed to load files from IndexedDB:", event);
      reject("Error loading files from IndexedDB");
    };
  });
},
async loadFileFromIndexedDB(fileName: string): Promise<ArrayBuffer | null> {
  const db = await this.openIndexedDB();
  if (!db) return null;

  return new Promise((resolve, reject) => {
    const transaction = db.transaction("files", "readonly");
    const store = transaction.objectStore("files");
    const getRequest = store.get(fileName);

    getRequest.onsuccess = () => {
      resolve(getRequest.result?.file || null);
    };
    getRequest.onerror = () => {
      reject("Error loading file from IndexedDB");
    };
  });
},
// Open IndexedDB
async openIndexedDB(): Promise<IDBDatabase | null> {
  //alert("Opening IndexedDB...");
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("OverlapDatabase", 1);

    request.onupgradeneeded = (event: any) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("overlap_data")) {
        db.createObjectStore("overlap_data", { keyPath: "id" });
      }
    };

    request.onsuccess = (event: any) => {
      resolve(event.target.result);
    };

    request.onerror = (event: any) => {
      console.error("Error opening IndexedDB:", event);
      reject(event.target.error);
    };
  });
},

// Helper function to download files locally
downloadOverlapExcelLocally(this: any, excelBlob: Blob, fileName: string, imageData: string | undefined, imageFileName: string | undefined) {
  // Download the Excel file
  const link = document.createElement("a");
  link.href = URL.createObjectURL(excelBlob);
  link.download = fileName;
  link.click();

  // Download the canvas image, if available
  if (imageData) {
    const imageLink = document.createElement("a");
    imageLink.href = imageData;
    imageLink.download = imageFileName!;
    imageLink.click();
  }
},
// Helper function to download image locally as a fallback if needed
downloadOverlapImageLocally(this: any, imageData: string, imageFileName: string) {
  const link = document.createElement("a");
  link.href = imageData;
  link.download = imageFileName;
  link.click();
},

removeDetectedCircle(this:any){
     this.circles=undefined;
     this.diameter=0;
     selectedCircleNo  = -1;   
     const cw = this.cameraOutput.width/2;
     const ch = this.cameraOutput.height/2;

     const dispCircleCtx =   this.$refs.dispCircleCanvas.getContext('2d');
     dispCircleCtx.clearRect(0, 0, cw, ch);
     this.selectCircleMode=false;
},

handleFocusEvent( this:any, event: FocusEvent) {

        if(this.viewState.showExcelMenu ){
        const target = event.target as HTMLElement;
        const position = target.getBoundingClientRect();
        

        if (target.tagName === 'INPUT' && target.className=='' ){
        
          if(this.viewState.selectCellMode){
            
            event.preventDefault(); 
            (event.target as HTMLElement).blur();
            return;
          }

          if(this.previousFocusPosition.x ==position.x  && this.previousFocusPosition.y ==position.y ){
        
            this.previousFocusPosition=position;
             
            return;
          }else {
              event.preventDefault(); 
              (event.target as HTMLElement).blur();
            this.previousFocusPosition=position;
            // (event.target as HTMLElement).blur();
            return;
          }
        }
          
        }
      // }
  },
  showLoadingView(this:any,timeout=8000){

      this.isLoading=true;
      setTimeout( ()=>{ this.isLoading=false; },timeout);   

    },
    async closeSerial(this:any){
    
    if(this.serial1){
    try{
      await this.serial1?.releaseReaderLock();  
      await this.serial1.close();
    }catch(e){ console.error(e);}
    }
    if(this.serial2){
     try{
      await this.serial2?.releaseReaderLock();
      await this.serial2.close();
    }catch(e){ console.error(e);}
    }
    if(this.serial3){
     try{

      await this.serial3?.releaseReaderLock();
      await this.serial3.close();
    }catch(e){ console.error(e);}
    }
    },
    async connectSerial(this:any){
     await this.closeSerial();
    if( !this.serial1){
        try{
        
        this.serial1 = new Serial( { baudRate:38400 , name : "serial1" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } } );
        
        }catch (e){  //baudRate:38400
          console.error(e);


        }
      }
      if(!this.serial2){
        try{
        
        this.serial2 = new Serial( { baudRate:38400 , name : "serial2" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } } );
     
        }catch (e){ 
          console.error(e);

        }
      }
      if(!this.serial3){
      try{
      this.serial3 = new Serial( { baudRate:38400 , name : "serial3" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } }  );
      }catch (e){ 
       
        console.error(e);
        }
      }

      var con = new SerialUsbAutoConnectionDelegate(); 
       
      con.push(this.serial1);
      con.push(this.serial2);
      con.push(this.serial3);      
      con.enable();
      
     await con.connectIfConnectionExists();


    },
    removeExcel(this:any){
  
       (document.getElementById("x-spreadsheet") as any).innerHTML = '';
        this.viewState.isExcelShow=false;
    },
    createExcel(this:any){
 
      if( (document.getElementById("x-spreadsheet") as any ).hasChildNodes() ) {
//        console.log("spreadsheet destory");

        (document.getElementById("x-spreadsheet") as any).innerHTML = '';
      }
      
      this.spreadsheet = 
        ( this.xSpreadSheetOption)?  (window as any).x_spreadsheet('#x-spreadsheet',this.xSpreadSheetOption)
       
       .loadData(this.xSpreadSheetData) :
        (window as any). x_spreadsheet( "#x-spreadsheet").loadData(  this.xSpreadSheetData );
        this.setXlsxFunc();
        this.xlsxUpdated = false ;
   
      
    },
async startSubCamera(this:any){
  if (this.selectedSubCameraDevice){
    
    window.localStorage.setItem('boreholeSubCamera', JSON.stringify(this.selectedSubCameraDevice) );
    
    try{
    this.mediaSubCamera =new Media({
          audio: false,
          video : {  
            frameRate: this.cameraRate,
             width: 1280, height: 800,
            // facingMode:  "user" ,
           // facingMode: { exact: "environment" },
             deviceId  :  this.selectedSubCameraDevice.deviceId
             }  
          } 
       ); 
    }catch (e:any){
            this.mediaSubCamera =new Media({
          audio: false,
          video : {  
            frameRate: this.cameraRate,
             width: 1280, height: 800,
            // facingMode:  "user" ,
           // facingMode: { exact: "environment" },
          //   deviceId  :  this.selectedSubCameraDevice.deviceId
             }  
          } 
       );
    }
       await this.mediaSubCamera?.open();
       this.$refs.videoSubCamera.srcObject = this.mediaSubCamera?.stream;
        this.$refs.videoSubCamera.addEventListener("loadedmetadata", () => {
               this.$refs.videoSubCamera.play();    
        });
        
        
  }
},
async initSettings(this: any){
      
      if (  this.$route.query.use && this.$route.query.use.includes ("subcamera") ){
          this.useSubCamera=true;      
      }
      if ( this.$route.query.use  && this.$route.query.use.includes ( "nonoverlapcirclearea" )){

          this.nonOverlapCircleAreaEnabled=true;      
      } 

    this.canvas2dCtx = this.$refs.canvas.getContext("2d");
    this.cameraInput = this.$refs.canvas;
    this.cameraOutput = this.$refs.canvasOutput;
    this.dispCanvas = this.$refs.dispCanvas;

    const mediaDevices :any = await window.navigator.mediaDevices.enumerateDevices();

    const boreholeSubCamera:any = (():any => { try {  return JSON.parse(window.localStorage.getItem('boreholeSubCamera') as any ); } catch (e) { return {}; } })();
 
    const boreholeMainCamera:any = (():any => { try {  return JSON.parse(window.localStorage.getItem('boreholeMainCamera') as any ); } catch (e) { return {}; } })();
 
    for (let len = mediaDevices.length, i = 0; i < len; i++) {
      const item = mediaDevices[i];
      if (item.kind === "videoinput") {
        
        const deviceId = item.deviceId;
        const label = item.label;
   
      if (boreholeSubCamera && boreholeSubCamera .deviceId == deviceId ) {
    
        this.selectedSubCameraDevice = boreholeSubCamera;
      }

      if ( boreholeMainCamera && boreholeMainCamera .deviceId == deviceId ) {
        
        this.selectedMainCameraDevice = boreholeMainCamera;
      }

      this.cameraDeviceIds.push({ deviceId, label });
    }
  }
  
  if ( !this.selectedMainCameraDevice ){
   
    this.selectedMainCameraDevice = this.cameraDeviceIds[0];  
  }
    
//   this.startMainCamera();

    // this.removeCalibData();
      
    window.document.addEventListener('click',this.outsideClickEvent );
      
   
      this.loadCalib();
      this.loadCurrentData();
   
      this.calibration. add = function( k:any,v:number) :number {
                const key:  keyof ICalibration = k;
               // console.log(key);
                const va = this[key] as number;

                    return  v + va  ;
            } ;
       this.calibration.ratio = (k:any ,v:number) :number => {
                       
                       const key:  keyof ICalibration = k;
                       const va = this[key] as number;
                       return  v / va  ;
                       
            } ;
      if(this.isGpsUsed){
        this.geolocation = new Geolocation (
            new class implements GeolocationCallback {
               public gps :any;
                constructor (gps:any){ this. gps = gps ; } 
                geolocationReceived = ( pos :any ):void => {
                     this.gps.lat = pos.coords.latitude;
                     this.gps.lon = pos.coords.longitude;
                }
                geolocationFailed = ( error :any ):void =>  {
                    console.error(error);
                    
                } 
            }(this.gps)  
        );
      }
      
      this. xlsxUpdated = true;
      this.$emit('show-header');
      //9600/19200/38400/115200, default 38400
      
      await this.drawCircleAreaial();

    // this.startCircleDetection();

    // this.startMainCamera();
     //this.startSubCamera();


},
initCircleDetectionWorker(this:any){
    
    console.log("init");
    if (null !== detectCircleTimeoutID ){ 
      clearTimeout( detectCircleTimeoutID  );
      detectCircleTimeoutID=null;

     }       
    if (circleDetectionWorker!==null) {
            
        circleDetectionWorker.terminate();
        console.log('Worker terminated');
        circleDetectionWorker=null;
     }
     circleDetectionWorker = new Worker('../../modules/app/opencv/workers/detectcircle.js', { type: 'module' });
     
     circleDetectionWorker.onerror = (e:any)=> {
      
      console.log(e);
      this.initCircleDetectionWorker();
    }
    
    circleDetectionWorker.onmessage =(d :any ) => {
        
        circleDetectionSem = false;
         console.log("detected!");
         
         console.log(d);

        if ( !d.data.error ){
              try{
                    if( !this.selectCircleMode){ //update the circle if selectCircleMode is false
                        
                        if (
                            d.data.data &&
                            d.data.data.circles &&
                            d.data.data.circles.rows
                        ){
                            this.circles = d.data.data.circles;    
                            selectedCircleNo  = -1;   
                            this.selectCircle(false);
                        }
                    }
                     
                }catch(e){ 
                  
                  this.initCircleDetectionWorker();
                  console.error(e);
                  //this.startCircleDetection();
                }
          } else {
               console.error(d);
              this.initCircleDetectionWorker();
          }
    }

     circleDetectionSem=false; 
},
  detectCircle(this:any){
               
        try{
        /*if( !this.selectedDetectionMode.includes('auto') ) { 
          circleDetectionSem=false;}*/
        
          if( !circleDetectionSem ) { //no other circle detecton in the process, //Sends a new request to the worker for circle detection
          console.log("detecting start.");
          circleDetectionSem = true;
          const para = JSON.parse(JSON.stringify(this.testCircleDetectionSetting)) ;
            
          // console.error("*Testing", this.colWidth, " this.rowHeight ", this.rowHeight);
          //---------------------^------------Original code end 
          if (this.videoWidth ===0 ){return; }
          this.colWidth   = this. videoWidth  * 1/3;   //start pt
          this.rowHeight   = this. videoHeight * 1/4;

            //visuslize image_zzt
            const imgData = this.canvas2dCtx.getImageData(0, 0, this.cameraInput.width, this.cameraInput.height);
            const h = this.videoHeight - this.rowHeight 
            const w = this.colWidth 
            // const imgData = this.canvas2dCtx.getImageData(0, 0, width, height);
            //this.canvas2dCtx.putImageData(imgData, 0, 0); 
            
             if (null !== detectCircleTimeoutID ){ 
              clearTimeout( detectCircleTimeoutID  ); 
              detectCircleTimeoutID=null;
            }
            
            detectCircleTimeoutID = setTimeout(()=>{ this.initCircleDetectionWorker(); } ,6000);
            
            //this.canvas2dCtx.putImageData(imgData, 0, 0); 
            circleDetectionWorker.postMessage({
                type : "detect",
                imgData : imgData,
                crop : { rect :   new cv.Rect (  this.colWidth,  this.rowHeight , w , h ) }, 
                setting :
                    (this.isDebugMode) ?
                     { 
                    dp: Number(para.dp),
                    minDist: Number(para.minDist),
                    param1 : Number(para.param1),
                    param2: Number(para.param2),
                    minRadius : Number(para.minRadius),
                    maxRadius : Number(para.maxRadius)
                } : CircleDetectionSetting[ CircleDetectionSetting.currentNum ]
       });         // setTimeout(()=>{ circleDetectionSem=false; },500)
                 //crop : { rect :   new cv.Rect (  this.colWidth,  this.rowHeight   , this.colWidth*1.5 , this.rowHeight * 3   ) },   
                 //crop : { rect :   new cv.Rect (  this.colWidth,  this.rowHeight   , this.colWidth*2 , this.rowHeight * 3   ) },      //org
       }
        } catch (e:any){
         // console.log(e);
           circleDetectionSem = false;
           }
    },

startCircleDetection(this:any){

    // manual mode
    if (!this.selectedDetectionMode.includes ("circle") ){ return ; }
    if (!this.selectedDetectionMode.includes ("auto") ){ 
        
        this.detectCircle();
        return ; 
    } 
    this.detectCircleIntervalID = setInterval( ()=>{
                
                if (this. videoWidth ==0){
                  return;
                }
                try{
                
               // alert(this.selectCircleMode);
                 if( !this.selectCircleMode){
                     
             //        console.log(this.gps);
                       // this.colWidth   = this. videoWidth  * 1/3;   //start pt
                       // this.rowHeight   = this. videoHeight * 1/4;
                      
                        this.colWidth   = this. videoWidth  * 1/3;   //start pt
                        this.rowHeight   = this. videoHeight * 1/4;
                    
                      if(this.selectedDetectionMode.includes("circle") ){
                           this.detectCircle();  
                         
                       }
                        
                    }   
                }catch(e:any){

                  console.error(e);
                }
                 // this.$refs.canvas.style.display = "none";
            }  ,this.detectCircleIntervalMs);
            
   
},

async uploadSubCameraImage(this:any,basefilename:string){

      // this.$refsを使ってビデオ要素にアクセス
      const videoElement:any = this.$refs.videoSubCamera;
      if (!videoElement) {
        console.error('ビデオ要素が見つかりません。');
        return;
      }

      // キャンバスを作成し、ビデオのフレームを描画
      const canvas:any = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
     
      canvas.height = videoElement.videoHeight;
      const context:any = canvas.getContext('2d');
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      context.drawImage( this.$refs.denshiShoKokubanCanvas , canvas.width-160, canvas.height-180, this.$refs.denshiShoKokubanCanvas.width ,this.$refs.denshiShoKokubanCanvas.height);
      const base64img:any=context.canvas.toDataURL('image/jpeg', 1.0);
      this.uploadBase64Image(base64img, basefilename+".jpg" );
  },
async changeMainCamera(this:any){
/*
 window.localStorage.setItem('boreholeMainCamera', JSON.stringify(this.selectedMainCameraDevice) );
 this.$router.push({ path: this.$route.path, query: { use : "subcamera", show: 'canvas' }});
 this.$router.go(0);
 */

  this.startMainCamera();

},
async startMainCamera(this:any){

  // this.clearConnection=false;
  window.localStorage.setItem('boreholeMainCamera', JSON.stringify(this.selectedMainCameraDevice) );

  navigator.mediaDevices.enumerateDevices()
    .then(function(devices) { // 成功時
      devices.forEach(function(device) {
  // デバイスごとの処理
   // console.log(device);
      });
  }).catch(function(err) { // エラー発生時
  
    console.error('enumerateDevide ERROR:', err);
  });

    try{
      this.media1= new Media({
          video : {   
            frameRate: this.cameraRate,
            width: 1280, height: 800, //width: 1920, height: 1080, //width: 1280, height: 800,
            //   facingMode: { exact: "environment" },
              deviceId: this.selectedMainCameraDevice.deviceId,
          
          }, 
          audio  :   false  ,
          secondary: {
             video :{
              width: 1280, height: 800, //width: 1920, height: 1080, //width: 1280, height: 800,
              //  facingMode: "user",
                deviceId: this.selectedMainCameraDevice.deviceId   
              } ,audio :false
          } 
      });
    }catch (e:any){

        this.media1= new Media({
          video : {   
            frameRate: this.cameraRate,
               width: 1280, height: 800,
            //   facingMode: { exact: "environment" },
          
          }, 
          audio  :   false   
      }); 

    }
      
      const video = this.$refs.videoMainCamera;
      await this.media1.open();
      this.$refs.videoMainCamera.srcObject = this.media1?.stream;
      this.$refs.videoMainCamera.addEventListener("loadedmetadata", () => {
      this.$refs.videoMainCamera.play();    
      });


    //zzt_250117
    //........................................................................
    // Autofocus control, set min-focuse value:100
    const track = this.media1.stream.getVideoTracks()[0];
    const capabilities = track.getCapabilities();
    
    if (capabilities.focusMode) {
      //alert('Camera supports focusMode:' + capabilities.focusMode);
      // Set focus to manual mode
      track.applyConstraints({
        advanced: [{ focusMode: 'manual' }], // Fix focus mode to manual
      }).then(() => {
        console.log('Focus mode set to manual');
      }).catch((error:any) => {
        console.error('Error setting focusMode to manual:', error);


      });
    }
      
    
    if (capabilities.focusDistance) {
      const midFocus = (capabilities.focusDistance.max + capabilities.focusDistance.min) / 20;
      //alert(midFocus);

      //alert('Camera supports focusDistance:'+ JSON.stringify(capabilities.focusDistance));
      // Set focus distance to a fixed value (e.g., minimum distance)
      track.applyConstraints({
        //advanced: [{ focusDistance: capabilities.focusDistance.max }], // Set focus to the smallest value
        advanced: [{ focusDistance: midFocus }]
      }).then(() => {
        console.log('Focus distance set to minimum value:', capabilities.focusDistance.min);
      }).catch((error:any) => {
        console.error('Error setting focusDistance to minimum value:', error);
      });
    } else {
      console.warn('Focus control not supported on this camera.');
    }
      
    //........................................................................

    
   //   this.presetVideo = (video:any)=>{
              
              

               // video.style.display = "none";
            this.$refs.canvas.style.display = "none";
              
            if(  this.selectedDetectionMode.includes('auto') ) { 
                this.startCircleDetection();
            }
             
             const dispCtx =   this.$refs.dispCanvas.getContext('2d');

// for circle detection.
                    
const width = dispCtx.canvas.width;
const height = dispCtx.canvas.height;

// 新しいCanvasを作成
updateCanvas.width = width;
updateCanvas.height = height;

updateSubCanvas.width = width;
updateSubCanvas.height = height;


const denshiShoKokubanCtx:any = this.$refs.denshiShoKokubanCanvas.getContext('2d');

var skip = this.skipWindowRate;
             video.addEventListener('play', () => {
              
                        const func = ()=>{ 
                            if( 1===(skip--) ){
                                skip=this.skipWindowRate;
                                 window.requestAnimationFrame(func);
                                return;
                            }
                           //zzt
    if(this.isGpsUsed){
                         this.geolocation.getCurrentPosition();
                      }
                  
const sx = 0; // video.videoWidth * 1/3 * 0.5; 
const sy = 0; //video.videoHeight * 1/3  * 0.5 ; 

const sWidth = video.videoWidth; 
const sHeight = video.videoHeight 

const h = video.videoHeight - this.rowHeight 
const w = this.colWidth 

this.videoWidth = video.videoWidth;
this.videoHeight = video.videoHeight; 

this.canvas2dCtx.drawImage(
    video, 
    sx, sy, sWidth, sHeight,
    sx, sy, sWidth, sHeight
);


 dispCtx.drawImage(
    video, 
    sx, sy, sWidth, sHeight,
    0, 0, sWidth/2, sHeight/2
);


if ( this.denshiShoKokuban.isNecessary ){

  
  // テキストのスタイルを設定
  denshiShoKokubanCtx.fillStyle = '#3a5f47'; // 黒板風の色
  denshiShoKokubanCtx.fillRect(0, 0, 160, 180);

  // フォントサイズを14pxに設定
  denshiShoKokubanCtx.font = '12px Arial';
  denshiShoKokubanCtx.fillStyle = 'white';

  // 各テキストをキャンバスの左上隅に配置
  const builder = this.denshiShoKokuban.builder;
  const projectName = this.denshiShoKokuban.projectName;
  const projectType = this.denshiShoKokuban.projectType;
  const site = this.denshiShoKokuban.site;
  const status = this.denshiShoKokuban.status;
  const len = this.ceilDemicalPoint ( this.fullLength  ,3 ) +'mm';
  const dia = this.ceilDemicalPoint( this.diameter ,1)+'mm';

  denshiShoKokubanCtx.fillText('1 施工者：'+builder, 10, 20);
  denshiShoKokubanCtx.fillText('2 工事名：'+ projectName, 10, 40);
  denshiShoKokubanCtx.fillText('3 工種：'+ projectType, 10, 60);
  denshiShoKokubanCtx.fillText('4 施工箇所名：'+site, 10, 80);
  denshiShoKokubanCtx.fillText('5 状況：'+status, 10, 100);
  denshiShoKokubanCtx.fillText('6 削孔長：'+ len , 10, 120);
  denshiShoKokubanCtx.fillText('7 削孔径：'+dia, 10, 140);

/*
  this.canvas2dCtx.fillStyle = '#3a5f47'; // 黒板風の色
  this.canvas2dCtx.fillRect(0, 0, 160, 180);

  // フォントサイズを14pxに設定
  this.canvas2dCtx.font = '12px Arial';
  this.canvas2dCtx.fillStyle = 'white';

  this.canvas2dCtx.fillText('1 施工者：'+builder, 10, 20);
  this.canvas2dCtx.fillText('2 工事名：'+ projectName, 10, 40);
  this.canvas2dCtx.fillText('3 工種：'+ projectType, 10, 60);
  this.canvas2dCtx.fillText('4 施工箇所名：'+site, 10, 80);
  this.canvas2dCtx.fillText('5 状況：'+status, 10, 100);
  this.canvas2dCtx.fillText('6 削孔長：'+ len , 10, 120);
  this.canvas2dCtx.fillText('7 削孔径：'+dia, 10, 140);
  */
}
                           /*  this.videoWidth = video.videoWidth;
                            this.videoHeight = video.videoHeight; 

                          this.canvas2dCtx.drawImage(  
                                video, 0,0 , 
                                video.videoWidth ,
                                video.videoHeight 
                            );  */
                            if (!this.detectCircleIntervalClear){
                               window.requestAnimationFrame(func);
                            }
                        }
                          if (!this.detectCircleIntervalClear){
                         window.requestAnimationFrame(func);
                          }
                   },true ); 
            
  },  //startMainCamera

      readCorrectionFile(this :any , e:any) {
         // console.error(e);
        
      const file = e.target.files[0]
      const reader:any = new FileReader()
     this.removeCalibData();
      reader.onload = ()=> {
         try{
           const data=JSON.parse(  atob( reader.result)  );
           
            if ( data.calibrationA ) { this.calibrationA = data.calibrationA ;}
            if ( data.calibrationB ) {  this.calibrationB = data.calibrationB;  }
            if ( data.calibrationLongLengthMeter ){ this.calibrationLongLengthMeter = data.calibrationLongLengthMeter; }
            if ( data.calibrationShortLengthMeter  ) { this.calibrationShortLengthMeter = data.calibrationShortLengthMeter; }
            if ( data.calibrationThresholdShortMeasurementsCentimeter  ) { this.calibrationThresholdShortMeasurementsCentimeter = data.calibrationThresholdShortMeasurementsCentimeter; }
            if (  data.calibrationCorrectionShortMeasurementsMillimetre ) { this.calibrationCorrectionShortMeasurementsMillimetre= data.calibrationCorrectionShortMeasurementsMillimetre;}
            if ( data.DiameterCorrectionValue ) {  this.DiameterCorrectionValue = data.DiameterCorrectionValue ;} 
            if ( data.calibration ) {  this.calibration = data.calibration; }    
            if ( data.testCircleDetectionSetting ){  this.testCircleDetectionSetting = data.testCircleDetectionSetting ; }
      

            this.hasCorrectionFile = true;
            this.saveCalib();
          }catch (e){ 
            console.error(e);
          }
      }
      reader.readAsText( file )
    },
      makeCorrectionFile (this:any){
          this.saveCalib();
         const dataStr= ( window as any) . localStorage.getItem("CalibData") ;  
         
           let str = btoa(dataStr);
        let ary = str.split(''); 
        let blob = new Blob(ary,{type:"text/plan"}); 
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob); 
        link.download = 'CorrectionFile.paletteiot'; 
        link.click(); 
        
      },
      removeCalibData (this:any){
          
            ( window as any) . localStorage.removeItem("CalibData");
      },
     // localStorage.clear();
      removeCurrentData (this:any){
            multi_circles = true; //to set multi_circles mode
           ( window as any) . localStorage.removeItem("RecentData");
      },
     loadCalib(this:any){
         try{
          const data=JSON.parse( ( window as any) . localStorage.getItem("CalibData") );
            if ( data.calibrationA ) { this.calibrationA = data.calibrationA;}
            if ( data.calibrationB ) { this.calibrationB = data.calibrationB;}
            if ( data.calibrationLongLengthMeter ){ this.calibrationLongLengthMeter = data.calibrationLongLengthMeter; }
            if ( data.calibrationShortLengthMeter  ) { this.calibrationShortLengthMeter = data.calibrationShortLengthMeter; }
            if ( data.calibrationThresholdShortMeasurementsCentimeter  ) { this.calibrationThresholdShortMeasurementsCentimeter = data.calibrationThresholdShortMeasurementsCentimeter; }
            if ( data.calibrationCorrectionShortMeasurementsMillimetre ) { this.calibrationCorrectionShortMeasurementsMillimetre= data.calibrationCorrectionShortMeasurementsMillimetre;}
            if ( data.DiameterCorrectionValue ) {  this.DiameterCorrectionValue = data.DiameterCorrectionValue ;} 
         //  console.log(data);
            if ( data.calibration ) {  
             
              this.calibration.serial1Value = data.calibration.serial1Value;
              this.calibration.serial2Value = data.calibration.serial2Value;
              this.calibration.serial3Value = data.calibration.serial3Value; 
            }  
            
            if(data.cameraRate ){ this.cameraRate = data.cameraRate; } 
            if(data.measurementTimerMs ){ this.measurementTimerMs = data.measurementTimerMs; } 
            if(data.detectCircleIntervalMs ){ this.detectCircleIntervalMs = data.detectCircleIntervalMs; } 
            
            if ( data.testCircleDetectionSetting ){  this.testCircleDetectionSetting = data.testCircleDetectionSetting ; } 
            if (data.hasCorrectionFile) { this.hasCorrectionFile=data.hasCorrectionFile; }


        }catch (e:any){ 
            //console.error(e);
             }
     },

     
     saveCalib(this:any){
      
      ( window as any) . localStorage.setItem(
        "CalibData" ,
        JSON.stringify( {
            hasCorrectionFile : this.hasCorrectionFile,
            calibrationA : this.calibrationA ,
            calibrationB : this.calibrationB ,
            calibrationLongLengthMeter:  this.calibrationLongLengthMeter ,
            calibrationShortLengthMeter:  this.calibrationShortLengthMeter ,
            calibrationThresholdShortMeasurementsCentimeter: this.calibrationThresholdShortMeasurementsCentimeter ,
            calibrationCorrectionShortMeasurementsMillimetre: this.calibrationCorrectionShortMeasurementsMillimetre ,
            DiameterCorrectionValue: this.DiameterCorrectionValue  ,
            calibration : this.calibration , 
            testCircleDetectionSetting : this.testCircleDetectionSetting,
            cameraRate : this.cameraRate , 
            measurementTimerMs : this.measurementTimerMs,
            detectCircleIntervalMs : this.detectCircleIntervalMs
           
           
        }));
        
     },

      wipeCurrentData(this:any){
    
          this.recentlyAcquiredData=[];
          this.isMeasuring=false;
          this.filename ="";
          this.selectedFullLengthCell= { 'row': -1 , 'col': -1 };
          this.selectedDiameterCell= { 'row': -1 , 'col': -1 };
          this.selectedGPSCell= { 'row': -1 , 'col': -1 };
        
          this. selectedDateTimeCell= { 'row': -1 , 'col': -1 };
          this.selectedRealData1Cell= { 'row': -1 , 'col': -1 };
          this.selectedRealData2Cell= { 'row': -1 , 'col': -1 };
          this.selectedRealData3Cell= { 'row': -1 , 'col': -1 };

          this.selectedLaserDistanceCell = { 'row': -1 , 'col': -1 }; // Reset laser-distance cell
          this.selectedYValueCell = { 'row': -1 , 'col': -1 };

          this.xSpreadSheetData= [{}];
          
          if(this.spreadsheet){
            
            this.spreadsheet.loadData([{}]);
            this.xlsxUpdated = false ;
          }
           

            this.viewState=  {
            isCanvasShow :true,
            isExcelShow :false,
            isSettingShow :false,
            isExcelFileMenuShow : false,
            isExcelSelectCellMenuShow:false,
            selectCellMode : false ,
            fullLengthCellSelected : false ,
            diameterCellSelected: false ,
            gpsCellSelected : false,
            DateTimeCellSelected:false,
            RealData1CellSelected:false,
            RealData2CellSelected:false,
            RealData3CellSelected:false,
            laserDistanceCellSelected: false, // New column for laser distance
            YValueCellSelected: false,        // New column for Y-value
            };
      },
     
    loadCurrentData (this:any){
           try{      
           // this.removeCurrentData();

//this.wipeCurrentData();

           const data=JSON.parse( ( window as any) . localStorage.getItem("RecentData") );
           
           if (data.filename) { this.filename = data.filename; }
          
           // if (data.acquiredData) {   this.acquiredData  = data.acquiredData ; } 
            
            if (data.xSpreadSheetData) {

                this.xSpreadSheetData  = data.xSpreadSheetData ;
                
                if(this.spreadsheet){
          
                  this.spreadsheet.loadData(this.xSpreadSheetData);
                    this.xlsxUpdated = true ;
                }
            }
            
            if (data.denshiShoKokuban){

                this.denshiShoKokuban=data.denshiShoKokuban;
            }
            if (data.recentlyAcquiredData){

                this.recentlyAcquiredData=data.recentlyAcquiredData;
            }
            
            if( data.isGpsUsed ) {this.isGpsUsed = data.isGpsUsed; }
            if( data.canBeUndone ) { this.canBeUndone=data.canBeUndone; }
          
            if (data.enableDiameter){ this.enableDiameter = data.enableDiameter;  }
            if (data.selectedFullLengthCell) {  this.selectedFullLengthCell = data.selectedFullLengthCell ; }
            if (data.selectedDiameterCell) {  this.selectedDiameterCell = data.selectedDiameterCell ; }
            if (data.selectedGPSCell) {   this.selectedGPSCell = data.selectedGPSCell ; }
            if (data.selectedDateTimeCell) {   this.selectedDateTimeCell = data.selectedDateTimeCell ; }
            if (data.selectedRealData1Cell) {   this.selectedRealData1Cell = data.selectedRealData1Cell ; }
            if (data.selectedRealData2Cell) {   this.selectedRealData2Cell = data.selectedRealData2Cell ; }
            if (data.selectedRealData3Cell) {   this.selectedRealData3Cell = data.selectedRealData3Cell ; }
            if (data.selectedLaserDistanceCell) {
                this.selectedLaserDistanceCell = data.selectedLaserDistanceCell; // New column
            }

            if (data.selectedYValueCell) {
                this.selectedYValueCell = data.selectedYValueCell; // New column
            }
            if (data.isMeasuring){ 

              this.isMeasuring = data.isMeasuring;
            }

            if (data.viewState){  
              
              for (const key in data.viewState) {
                if (Object.prototype.hasOwnProperty.call(data.viewState, key)) {
                 
                   this.viewState[key] = data.viewState[key];
                }
              }
            }
            
          }catch (e){ console.error(e); }
      },
       
     saveCurrentData(this:any){
      

      ( window as any) . localStorage.setItem(
        "RecentData" ,
        JSON.stringify( {
            isGpsUsed : (true === this.isGpsUsed),
            enableDiameter : (true === this.enableDiameter),
            canBeUndone : ( true === this.canBeUndone),
             denshiShoKokuban:this.denshiShoKokuban,
            isMeasuring : this.isMeasuring,
            filename : this.filename ,
            recentlyAcquiredData:this.recentlyAcquiredData,
           // acquiredData : this.acquiredData ,  
            xSpreadSheetData :    this.xSpreadSheetData ,//this.spreadsheet.getData(),
            selectedFullLengthCell: this.selectedFullLengthCell ,
            selectedDiameterCell: this.selectedDiameterCell ,
            selectedGPSCell:  this.selectedGPSCell ,
            DateTimeCellSelected: this.DateTimeCellSelected,
            selectedDateTimeCell: this.selectedDateTimeCell,
            RealData1CellSelected: this.RealData1CellSelected,
            RealData2CellSelected: this.RealData2CellSelected,
            RealData3CellSelected: this.RealData3CellSelected,
            selectedRealData1Cell:this.selectedRealData1Cell,
            selectedRealData2Cell:this.selectedRealData2Cell,
            selectedRealData3Cell:this.selectedRealData3Cell,
            selectedLaserDistanceCell: this.selectedLaserDistanceCell, // New column
            selectedYValueCell: this.selectedYValueCell, 
             viewState : this.viewState
        }));
          
      },
  
      gpsLink (this:any){
            if (window.confirm("位置情報の確認 外部リンクを開きますか？ ")){
                 window.open("https://www.google.co.jp/maps/@"+this.gps.lat+","+this.gps.lon+",15z", '_blank');
          }
      },
      raitoValue(v:number, calibrationLongLengthMeter : number , calibrationShortLengthMeter :number ){
         if(0.5 < v){
             return v/ calibrationLongLengthMeter ;
         } 
         return v /  calibrationShortLengthMeter;
      }, 
       addValue(v:number, calibrationLongLengthMeter : number , calibrationShortLengthMeter :number ){
        if(0.5 < v){
             return calibrationLongLengthMeter -v;
         } 
         return  calibrationShortLengthMeter -v;  
      },
      clearCalibration (this:any){

            this.calibration.serial1Value=0;
            this.calibration.serial2Value=0;
            this.calibration.serial3Value=0;

      },
      async calibrationStart( this:any ){

          this.clearCalibration();
          await this.measurement();
          
      },
      async calibrationAdd (this:any){
            this.calibration.use = "add";

            if( 0.5< this.serial1Value){
                  this.calibration.serial1Value = this.calibrationLongLengthMeter - this.serial1Value  ;      
            } else {
                  this.calibration.serial1Value =  this.calibrationShortLengthMeter - this.serial1Value  ;           
            }
            if( 0.5< this.serial2Value){
                  this.calibration.serial2Value = this.calibrationLongLengthMeter -this.serial2Value  ;      
            } else {
                  this.calibration.serial2Value = this.calibrationShortLengthMeter - this.serial2Value  ;           
            }
            
           if( 0.5< this.serial3Value){
                  this.calibration.serial3Value = this.calibrationLongLengthMeter - this.serial3Value  ;      
            } else {
                  this.calibration.serial3Value =  this.calibrationShortLengthMeter - this.serial3Value ;           
            }
           
            this.saveCalib();
            
            // await this.initSettings();
           alert( "設定しました。" );
      },
      calibrationRatio (this:any){
            this.calibration.use = "ratio";
        
            if( calibrationLongLengthMeterThreshold < this.serial1Value){
                  this.calibration.serial1Value = this.serial1Value /  this.calibrationLongLengthMeter   ;      
            } else {
                  this.calibration.serial1Value = this.serial1Value /  this.calibrationShortLengthMeter ;           
            }
            if( calibrationLongLengthMeterThreshold < this.serial2Value){
                  this.calibration.serial2Value = this.serial2Value / this.calibrationLongLengthMeter   ;      
            } else {
                  this.calibration.serial2Value = this.serial2Value /  this.calibrationShortLengthMeter ;           
            }
            
           if( calibrationLongLengthMeterThreshold < this.serial3Value){
                  this.calibration.serial3Value = this.serial3Value /  this.calibrationLongLengthMeter  ;      
            } else {
                  this.calibration.serial3Value = this.serial3Value /  this.calibrationShortLengthMeter ;           
            }
             alert( "設定しました。" );
            
      },
      ceilDemicalPoint (v:any , unit :number ){
  
       var s =String(v);
       var ss = s.split('.');
       if(1 < ss.length){
           
           var u:any = "";
           for ( var i=0; i< ss[1].length ; i ++ ){
               if( i < unit ){
                 u += ss[1][i];
               }
           } 
       }else {
        return s;
       }
       if(u===undefined) { u = "0"; }
       return ss[0] +"." + u;
      /* 
      桁溢れするので↑の実装..
       const u = (10**unit);
        var r = v * u;
       console.log(v);
                console.log(r);
      
         r = Math.ceil(v) 
        return r / u ;  
        */
      },
      exchangeToColName( num : number ){
        return ["","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O", "P","Q","R","S","T","U","V","W","X","Y","Z"][num];
      },
      
      async showArea(this : { viewState:any } ,area:string){
        
        this.viewState.isCanvasShow = false;
        this.viewState.isExcelShow = false;
        this.viewState.isSettingShow = false;
        switch( area ){
            case 'Canvas' :
                this.viewState.isCanvasShow = true;
               // await new Promise(s => setTimeout(s, 1500))
            break;
            case 'Excel':
                this.viewState.isExcelShow = true;              
            break;
            case 'Setting':
                 this.viewState.isSettingShow = true;
            break;
        }  
        
    },
    showExcelMenu (this:{ viewState:any},state:string){
        
        switch(state){
            case 'File' :
             this.viewState.isExcelSelectCellMenuShow = false;
             this.viewState.isExcelFileMenuShow = !this.viewState.isExcelFileMenuShow ;
            break;
            case 'CellSelection': 
            this.viewState.isExcelFileMenuShow = false ;
            this.viewState.isExcelSelectCellMenuShow = !this.viewState.isExcelSelectCellMenuShow;
            break;
      }
    },
        onDebugMode(this:any){
        this.debugModeCount ++  ;
        if ( 29 < this.debugModeCount  ){
            this.isDebugMode=true;
        }
    },
    selectCircle(this:any, modeOn = true ){
      
        //alert(this.cameraOutput.width);
        this.selectCircleMode = modeOn;

        if ( !this.circles  ){ return ; }
        if ( !this.circles.cols  ){ return ; }
    
         const dispCircleCtx =   this.$refs.dispCircleCanvas.getContext('2d');

        let src = cv.imread(   this.cameraInput  );// this.cameraInput);  
        let dst = new cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC4);
        // dst.setTo(new cv.Scalar(0,10,0,1));
        //alert("this.circles.cols " + this.circles.cols);

     for (let i = 0; i < this.circles.cols; ++i) {   
            let x = this.circles.data32F[i * 3] + this.colWidth;
            let y = this.circles.data32F[i * 3 + 1] + this.rowHeight;
            let radius = this.circles.data32F[i * 3 + 2];
            let center = new cv.Point(x, y);
            //set for adjust_geen
            overlayX = x; overlayY = y; 
            
             //cv.circle(dst, center, radius, white);
            cv.circle(dst, center, radius, nextCirclecol, 3);
        }
        selectedCircleNo = ( selectedCircleNo >= this.circles.cols-1  ) ? 0 : selectedCircleNo + 1 ;
        if(this.selectCircleMode == false){
          multi_circles = false;   
        }
        //alert("multi_circles " + multi_circles);
         
        this.currentCircle.radius = this.circles.data32F[selectedCircleNo * 3 + 2];
        this.currentCircle.center = new cv.Point(
            this.circles.data32F[selectedCircleNo * 3] + this.colWidth , // x
            this.circles.data32F[selectedCircleNo * 3 + 1]  + + this.rowHeight // y
        );
        
        cv.circle(dst,  this.currentCircle.center,  this.currentCircle.radius, green,3);     
        cv.imshow( this.cameraOutput, dst);

        const cw = this.cameraOutput.width/2;
        const ch = this.cameraOutput.height/2;
        dispCircleCtx.clearRect(0, 0, cw, ch);
        dispCircleCtx.drawImage(
         this.$refs.canvasOutput, 
        0, 0, this.cameraOutput.width, this.cameraOutput.height,
        0, 0, cw, ch );

        
        src.delete();  //zzt
        dst.delete(); 
        
        this.calculateDiameter();

      //***************************************************//zzt_240626
        
      //find the circle area from overlapped circles--> compare radii, cen_to_cen > rad--> find overlapArea
      let center_i = this.currentCircle.center ;    // get the selected circle center information 
      
      let nextCenter = null;  //get the next circle's center as well
      let nextRadius = 0 ; 
      if (this.circles.cols === 2) {

        let src = cv.imread(  this.cameraInput  );// this.cameraInput);  
        let dst = new cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC4);

        let nextCircleNo = (selectedCircleNo >= this.circles.cols - 1) ? 0 : selectedCircleNo + 1;
        nextCenter = new cv.Point(
            this.circles.data32F[nextCircleNo * 3] + this.colWidth, // x
            this.circles.data32F[nextCircleNo * 3 + 1] + this.rowHeight // y
        );
        nextRadius = this.circles.data32F[nextCircleNo * 3 + 2];
        //console.log('nextRadius:', nextRadius); 
      } //if (this.circles.cols === 2)
     
     // Calculate the real-world distance between the two centers
      if(null===nextCenter){return;}
      const pixelDistance = Math.sqrt(
        Math.pow(center_i.x - nextCenter.x, 2) +
        Math.pow(center_i.y - nextCenter.y, 2)
      );

      if ( this.nonOverlapCircleAreaEnabled ){
        const radiusDifference = Math.abs(this.currentCircle.radius - nextRadius); // Compare the two radii 
        if (radiusDifference <= 10) {
            this.calculateRealWorldDistance(pixelDistance);  //to find nonOverlapArea
        }
      }/* else {
        console.log('radiusDifference more than 10 units.', radiusDifference);
      }*/
      //****************************************************//zzt_240626
        
}, //selectCircle(this:any, modeOn = true )
    
calculateRealWorldDistance( pixelDistance:number ){ 
   if(touch_theta <0){ return ; }
   
  
   const thea_rad = ( ( touch_theta) * Math.PI / 180 ) ;
   let centers_dis =   touch_depth * Math.tan ( thea_rad  ) ;
   centers_dis *= (pixelDistance / screen_width); //extra one

   if( centers_dis < 0 ){ return ;}
     
   let cen_to_cen =  centers_dis * 1.05; 

   const nonOverlapArea = this.calculateCircleArea(cen_to_cen);

   (this as any).nonOverlapCircleArea = nonOverlapArea;
   //console.log("nonOverlapArea", nonOverlapArea);
   //alert("nonOverlapArea in calculateRealWorldDistance" + nonOverlapArea); 
   return cen_to_cen;
},
calculateCircleArea(  this: any, cen_to_cen: number ){  //zzt_240626 //find the area of non-overlap area of circle
  //console.log("calculate overlap circle area");
  
  let each_radius = this.diameter / 2.0 ;
  
  if (cen_to_cen >= 2 * each_radius) {
    // No overlap
    //return Math.PI * each_radius * each_radius;   //whole circle area
    return 0; 
  } else if (cen_to_cen === 0) {
    // Complete overlap
    return 0;
  } else {
    // Partial overlap
    const overlapArea = (2 * each_radius * each_radius * Math.acos(cen_to_cen / (2 * each_radius))) - (0.5 * cen_to_cen * Math.sqrt(4 * each_radius * each_radius - cen_to_cen * cen_to_cen));
    const totalCircleArea = Math.PI * each_radius * each_radius;  //pixrx2
    const nonOverlapArea = totalCircleArea - (overlapArea / 2);
    return nonOverlapArea;
  } 
},//calculateCircleArea  zzt_240626 

calculateRadiusInPixel(this: any, realWorldRadius: number,canvas:HTMLCanvasElement) {
    //let cameraOutputWidth = this.cameraOutput.width || 560; // Replace with actual width if defined elsewhere
    //let cameraOutputWidth = canvas.width || 560;

    let cameraOutputWidth  = this.videoWidth ;
    let currentCircleRadiusPixel = 0;
    if(touch_depth){
      const theta_rad = Math.atan(realWorldRadius / touch_depth);
      const theta_deg = theta_rad * (180 / Math.PI);
      currentCircleRadiusPixel = (theta_deg / hov) * cameraOutputWidth; // In pixels    
    }
    //alert("currentCircleRadiusPixel " + currentCircleRadiusPixel);
    return currentCircleRadiusPixel;
}, 
findRealWorldRadius(this: any, currentCircleRadiusPixel: number) {
    //alert("touch_depth " + touch_depth  + " capture_touch_depth " + capture_touch_depth);
    //let cameraOutputWidth = screen_width || canvas.width;  // Use screen width or canvas width
    //let cameraOutputWidth = screen_width 
    //let cameraOutputWidth = this.videoWidth ; 
    let cameraOutputWidth = overlap_screen_width ;  
    
    let cameraHFOV = hov;  // Horizontal field of view in degrees
    //alert("cameraOutputWidth " + cameraOutputWidth + " hov " + hov); 
    // Step 1: Convert pixels to degrees (theta)
    let theta_deg = (currentCircleRadiusPixel / cameraOutputWidth) * cameraHFOV;

    // Step 2: Convert degrees to radians
    let theta_rad = theta_deg * (Math.PI / 180);

    // Step 3: Find the real-world radius in mm using inverse tangent
    if(capture_mode == 1){
      touch_depth = capture_touch_depth ; //use the touch depth since capture 
    }

    let realWorldRadius = Math.tan(theta_rad) * touch_depth;
    
    return realWorldRadius;  // The real-world radius in mm
},

touchSetDetectingPoint(
  // this : { dispCanvas: HTMLCanvasElement}, 
  this :any , 
  event: MouseEvent | TouchEvent) {  //zzt 240620
    //alert("touchSetDetectingPoint");

     green_adjust = false; //green-cirlce canvas close
     const canvas = this.$refs.dispOverlayCircleCanvas;
     event.preventDefault();
     //alert("touchSetDetectingPoint getLaser  " + getLaser);
      if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        if(getLaser == 0){ 
          this.handleCanvasTouch(touch.clientX, touch.clientY, canvas);
        }else{ //to get laser-depth in serach button
          if(multi_circles){ 
            this.getLaserDistance(touch.clientX, touch.clientY, canvas);
          }
        }
      } else if (event instanceof MouseEvent) {
        if(getLaser == 0){ //
          this.handleCanvasTouch(event.clientX, event.clientY, canvas);
        }else{ //to get laser-depth in serach button
          if(multi_circles){
            this.getLaserDistance(event.clientX, event.clientY, canvas);
          }
        } 
      }//else

}, //touchSetDetectingPoint

getLaserDistance(this: any, clientX: number, clientY: number, canvas: HTMLCanvasElement) {  
  const rect = canvas.getBoundingClientRect();
  const x = clientX - rect.left;
  const y = clientY - rect.top;
  //alert("---getLaserDistance x " + x + " y " + y + " overlap_circles.length " + overlap_circles.length);
  if (x !== null && y !== null) {
      if(overlap_circles.length > 0){
          this.isTouchInsideCircle(x, y, canvas);
      }else{
        alert("Draw circle at first!");
      }
  } //if (x !== null && y !== null) 

}, //getLaserDistance 
handleCanvasTouch(this: any, clientX: number, clientY: number, canvas: HTMLCanvasElement) {   
      //alert("initial_radius " + initial_radius);
      
      //const rect = this.$refs.canvas.getBoundingClientRect();
      const rect = canvas.getBoundingClientRect();
      const x = clientX - rect.left;
      const y = clientY - rect.top;
      //alert("x " + x + " y "  + y );

      overlayX = x;
      overlayY = y;

      //................................................................
      //use for projection  -> this.findRealWorldRadius
      const LaserStartingPoint =  this.DiameterCorrectionValue.detectStartPoint ;
      touch_depth = (  ( this.avgLength - this.calibrationA ) /this.calibrationB  ) + LaserStartingPoint  ;  //calculate diameter by depth (far depth->smaller dia etc..)

      if(multi_circles){ 
          let circleID = 1;
          dynamicRadiusPixel = initial_radius ;
          if(adjustedDynamicRadiusPixel > 0){
              dynamicRadiusPixel = adjustedDynamicRadiusPixel ;  
          }
          if(overlap_circles.length == 0){
            
            let radiusMM = this.findRealWorldRadius(dynamicRadiusPixel); // Calculate pixel radius
            const scale = radiusMM / dynamicRadiusPixel;
            const xReal = 2.0 * x * scale;
            const yReal = 2.0 * y * scale;
            let diameterMM = 2 * radiusMM;
            const nonOverlapAreaMM = Math.PI * Math.pow(radiusMM, 2); 
            const partialOverlapArea = 0;
            const areaMM = nonOverlapAreaMM + partialOverlapArea;
            let nonOverlapVolume = nonOverlapAreaMM * this.fullLength ; 
            overlap_circles.push({  
                x: x,
                y: y,
                xReal:xReal,
                yReal:yReal,
                circleId: circleID,
                fromCircle: "c1-c1",
                cen_to_cenMM: 0,
                radius: dynamicRadiusPixel,  //to draw circle
                diameterMM: diameterMM,
                area: areaMM,  //to use for the first time to find non-overlap area if second circle comes
                touch_depth: touch_depth, 
                full_depth: this.fullLength,
                longest_depth: 0,
                relatedOverlapArea: 0,
                partialOverlapArea: partialOverlapArea,
                prevNonOverlapArea: 0,
                nonOverlapArea: nonOverlapAreaMM,
                nonOverlapVolume: nonOverlapVolume,
                touchX: x,
                touchY: y,
                baseLineY: 0
            }); //push
            this.diameter = diameterMM;
        }else{
            const push_no = 1;
            const move = 0;
            circleID+=1;
            this.calculateNonOverlapAreaMultiCircles(dynamicRadiusPixel, x, y, push_no, move, circleID);
        }//if(overlap_circles.length == 0)

        //draw circles
        if(overlap_circles.length > 0) {
          if (canvas) {
              const ctx = canvas.getContext('2d');
              if (ctx) {
                  ctx.clearRect(0, 0, canvas.width, canvas.height);
                  if(savedCanvas){
                    ctx.drawImage(savedCanvas, 0, 0);
                  } 
              }
        }//if
          //let prev_x = 0; let prev_y = 0;
          overlap_circles.forEach((circle, index) => {

              this.drawCircleImage(circle.x, circle.y, circle.radius, canvas, index, 0, 0, 0);

              /*
              if(circle.x == prev_x && circle.y == prev_y){
                pos_overlap = 1;
                this.drawCircleImage(circle.x, circle.y, circle.radius, canvas, index, pos_overlap, 0, 0, 0);
              }else{
                this.drawCircleImage(circle.x, circle.y, circle.radius, canvas, index, pos_overlap, 0, 0, 0);
              }
              prev_x = circle.x; prev_y = circle.y;
              */
          });
        }//if
        
      }//if(multi_circles)
      
      //green_circle
      if(green_circle && !multi_circles ){
          let realWorldRadius = 0;
          const detectedPipeRadius = this.diameter / 2;  //get thid dia from calculateDiameter
          if(detectedPipeRadius > 20.0){
              realWorldRadius = detectedPipeRadius;
          }else{
              realWorldRadius = this.scaleRealWorldDiameter * 10/ 2.0;   
          }
          dynamicRadiusPixel = this.calculateRadiusInPixel(realWorldRadius,canvas);  //rad in pixel
          if(adjustedDynamicRadiusPixel > 0){
            dynamicRadiusPixel = adjustedDynamicRadiusPixel ;  
          }
          //let dynamicRadiusMM = this.findRealWorldRadius(dynamicRadiusPixel);
          //alert("dynamicRadiusPixel " + dynamicRadiusPixel + "dynamicRadiusMM " + dynamicRadiusMM);
          this.drawCircleImage( x , y, dynamicRadiusPixel,canvas,0, 0, 0, 0 );
      } //if(!multi_circles)
         
},

drawCircleImage(this:any, x: number, y: number, dynamicRadiusPixel: number, canvas: HTMLCanvasElement, index:number, touchX:number, touchY:number, fullDepth:number) {
      /*
      const x = circle.x;
      const y = circle.y;
      const dynamicRadiusPixel = circle.dynamicRadiusPixel;
      const touchX = circle.touchX;
      const touchY = circle.touchY;
      constfullDepth = circle.full_depth;
      */

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      let img_width = 2.0 * dynamicRadiusPixel;
      let clearTime = 60000;

      if (ctx) {
         //green_circle
         if(green_circle && green_adjust){
            //alert("comes to draw" + "x " + x  + " y " +  y  + " rad " + dynamicRadiusPixel );
            ctx.save();   //half-canvas
            ctx.translate(x, y);
            ctx.scale(0.5, 0.5); 
            ctx.translate(-x, -y);

            ctx.beginPath();
            ctx.arc(x, y, dynamicRadiusPixel, 0, 2 * Math.PI);  
            ctx.strokeStyle = 'green';
            ctx.stroke();
            ctx.restore();
            //update green diameter
            let diaMM = 2 * (this.findRealWorldRadius(dynamicRadiusPixel));
            const formatted_diaMM = diaMM.toFixed(2);
            this.diameter = formatted_diaMM ;
          }//green_adjust

          //green_circle
          if(green_circle && !multi_circles && !green_adjust){
            let image = new Image();
            image.src = multi_circles ? this.alternateCircleRed.src : this.circleImage.src; 
            image.onload = () => {
                  ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
                  const img_half_width = img_width / 2;
                  const halfRadius = dynamicRadiusPixel / 2.0;
                  ctx.drawImage(this.circleImage, x - halfRadius, y - halfRadius, img_half_width, img_half_width); //draw at top-left corner of image POS

                  image.onerror = () => {
                    console.log('Failed to load the image.');
                  };
              };//image.onload

          }// if(!multi_circles)

          //multi_circles
          if (multi_circles && !green_circle) {
            //clearTime = 40000;
            ctx.save();   //half-canvas
            ctx.translate(x, y);
            ctx.scale(0.5, 0.5); 
            ctx.translate(-x, -y);
            //..................
            ctx.beginPath();
            ctx.arc(x, y, dynamicRadiusPixel, 0, 2 * Math.PI);  // Draw a circle at (x, y) with the specified radius
            //ctx.fillStyle = multi_circles ? 'rgba(255, 0, 0, 0.3)' : 'rgba(0, 0, 255, 0.3)';  // Color based on multi_circles flag
            //ctx.fill();  // Fill the circle with the color
            ctx.strokeStyle  = multi_circles ? 'red' : 'cyan';  // Set stroke color based on multi_circles flag
            //if(longestDepth > 0 && longestDepthID == index){
              //ctx.strokeStyle = 'cyan';
            //}
            //ctx.strokeStyle = multi_circles ? 'rgba(255, 0, 0, 2)' : 'rgba(0, 255, 255, 2)';
            ctx.lineWidth = 2;
            ctx.stroke();  // Draw the circle outline
            //longestDepth = 0; //refresh

             //apply txt non-overlap area
              const circleX = x ;
              const circleY = y ;

              // Small dot at the center
              if (dynamicRadiusPixel > 0) {
                ctx.beginPath();
                ctx.arc(circleX, circleY, 2, 0, 2 * Math.PI);  
                ctx.fillStyle = "red";  // Color for the center dot
                ctx.fill();  // Fill the dot
              }
              // Draw the circle ID near the center
              if (dynamicRadiusPixel > 0) {
                ctx.font = "24px Arial";
                ctx.fillStyle = "green";  // Color for the ID text
                let x_space = 10;
                ctx.fillText(`ID: ${index + 1}`, circleX - x_space, circleY - 10);  // Adjust position as needed
                
              }
              ctx.restore();

              if(fullDepth>0){
                  ctx.beginPath();
                  ctx.arc(touchX, touchY, 2, 0, 2 * Math.PI);
                  ctx.fillStyle = 'cyan'; // Color of the spot
                  ctx.fill();
                  // Display the depth as text near the touch point
                  ctx.font = '14px Arial';
                  ctx.fillStyle = 'cyan'; // Color of the text
                  //ctx.fillText(`削孔長: ${circle.full_depth}`, touchX + 5, touchY - 5);
                  ctx.fillText(`削孔長: ${fullDepth.toFixed(2)}mm`, touchX + 5, touchY - 5); 
              }
               // Display text at canvas corner 
              if(index == overlap_circles.length -1){ //check reach to last-circle?
                const clearWidth = canvas.width; // Clear the full width for safety
                ctx.clearRect(canvas.width - clearWidth + 250, 0, clearWidth, overlap_circles.length * 20);
                //ctx.clearRect(canvas.width - 200, 0, 200, overlap_circles.length * 20); // Clear the area for text display
                 // Draw static title (labels)
                //const title = `ID r(mm) c-to-c(mm) Partial-A(mm²) Non-overlapA(mm²) Area(mm²)`;

                const startX = 50; // Starting x-coordinate
                //const colSpacing = [157, 177, 225, 320, 455, 590];
                //const colSpacing = [10, 40, 85, 180, 310, 450, 590]; 
                //const colSpacing = [50, 80, 130, 220, 310, 410, 500, 570];
                const colSpacing = [5, 20, 60, 140, 235, 320, 425, 515, 575];
                //const colSpacing = [5, 20, 60, 140, 225, 305, 395, 485, 545, 610];
                const startY = 20; // Starting y-coordinate
                const rowHeight = 20; // Height of each row
                // Draw static title (labels)
                //const titles = ["ID", "r(mm)", "c-c(mm)", "EachOA(mm²)", "TotalOA(mm²)", "NonOA(mm²)", "A(mm²)", "V(mm²)"];
                const titles = ["ID", "r(mm)", "c-c(mm)", "EachOA(mm²)", "TotalOA(mm²)", "PrevOA(mm²)", "NonOA(mm²)", "A(mm²)", "V(mm²)"];
                ctx.font = "bold 12px Arial"; // Set font style for the title
                ctx.fillStyle = "black"; // Set color for the title
                titles.forEach((title, i) => {
                  ctx.fillText(title, colSpacing[i], startY); // Position each title in its column
                });
                

                overlap_circles.forEach((circle, index) => {
                  
                  //find radius
                  let radiusMM = this.findRealWorldRadius(circle.radius);
                  let dia = 2 * radiusMM;
                  let baseLineY = this.baseLineA + (this.baseLineB * dia) ;
                  circle.baseLineY = baseLineY;

                  const combinedColumn = `${circle.fromCircle}:${circle.cen_to_cenMM.toFixed(2)}`; // Combine values into one column
                  const values = [
                    circle.circleId,
                    radiusMM.toFixed(2),
                    combinedColumn,
                    circle.relatedOverlapArea.toFixed(2),
                    circle.partialOverlapArea.toFixed(2),
                    circle.prevNonOverlapArea.toFixed(2),
                    circle.nonOverlapArea.toFixed(2),
                    circle.area.toFixed(2),
                    circle.nonOverlapVolume.toFixed(2),
                    //baseLineY.toFixed(2),
                  ];
                    //const text = `ID-:${circle.circleId}, r:${formatted_radiusMM}mm, ${circle.fromCircle}: ${circle.cen_to_cenMM.toFixed(2)}mm, PartialA:${circle.partialOverlapArea.toFixed(2)} mm², Non-overlapA:${circle.nonOverlapArea.toFixed(2)} mm², A:${circle.area.toFixed(2)} mm²`;
                    ctx.font = "15px Arial"
                    ctx.fillStyle = "red";
                    //ctx.fillText(text, canvas.width - 360, 20 + index * 20); 
                    //ctx.fillText(text, 85, 20 + index * 20); 
                    values.forEach((value, i) => {
                      ctx.fillText(value, colSpacing[i], startY + rowHeight * (index + 1)); // Position each value in its column
                    });
                  
                }); //for each
              } //if display text

          }//if (multi_circles)

          if ( clearCircleScaleTimerID ){
              clearTimeout(clearCircleScaleTimerID)
          }
          clearCircleScaleTimerID =setTimeout( ()=>{ ctx.clearRect(0, 0, canvas.width, canvas.height);  } ,clearTime );          
        }//if (ctx)

      // Store the current position for future redraws when radius changes
      overlayX = x;
      overlayY = y;
      global_canvas = canvas ;
}, //drawCircleImage

calculateNonOverlapAreaMultiCircles(this: any, currentRadiusPixel: number, x: number, y: number, push_no: number, move: number, circleID:number) {
  // Convert current radius from pixels to mm

  const currentX = x ;
  const currentY = y ;

  let currentCircleID = 0;
  let cen_to_cenMM_ = "";

  //let currentCircleID = 0;
  let currentRadiusMM = this.findRealWorldRadius(currentRadiusPixel);
  const scale = currentRadiusMM / currentRadiusPixel;
  const xReal = 2.0 * x * scale;
  const yReal = 2.0 * y * scale;
  //const radInPixel = this.calculateRadiusInPixel(currentRadiusMM, global_canvas);
  let currentDiameterMM = 2 * currentRadiusMM;
  this.diameter = currentDiameterMM;
  let currentCircleAreaMM = Math.PI * Math.pow(currentRadiusMM, 2);  // Full area of the current circle in mm²
  //alert("currentRadiusMM "+ currentRadiusMM + " currentCircleAreaMM " + currentCircleAreaMM);

  // Loop through all previous circles to calculate overlap
  let newCircleNonOverlapArea = currentCircleAreaMM; //for increase and decrease (to show area of new radius)
  let newCircleNonOverlapVolume = 0 ;
  let newCirclePartialOverlapArea = 0 ;
  let fromCricle = null;
  let newCircleRelatedOverlapArea = 0;

  //let previousCircle = overlap_circles[overlap_circles.length - 1];
  
  overlap_circles.forEach((previousCircle, index) => {
  //for (let index = 0; index < overlap_circles.length; index++) {

    previousCircle = overlap_circles[overlap_circles.length - 1];
    if(move == 1 && previousCircle.circleId > 1){
        previousCircle = overlap_circles[overlap_circles.length - 2];
    }

    currentCircleID = overlap_circles.length + 1;
    //const currentTo = previousCircle.circleId + 1;
    fromCricle = "c" + previousCircle.circleId + "-c" + currentCircleID;
    // Calculate the center-to-center distance between existing and new circle
    const cen_to_cenPixel = Math.sqrt(Math.pow(previousCircle.x - x, 2) + Math.pow(previousCircle.y - y, 2));
    const testcenter = Math.sqrt(Math.pow(previousCircle.xReal - xReal, 2) + Math.pow(previousCircle.yReal - yReal, 2));
    
    //const cen_to_cenMM = this.calculateRealWorldDistance(cen_to_cenPixel);
    const double_cen_to_cenPixel = 2 * cen_to_cenPixel ; //2 times as the drawing canvas use half-scale
    const cen_to_cenMM = this.findRealWorldRadius(double_cen_to_cenPixel);
    cen_to_cenMM_ = cen_to_cenMM;
    //alert("testcenter " + testcenter + " cen_to_cenMM " + cen_to_cenMM)
    
    // Convert the radius of the existing circle to mm
    const previousRadiusMM = this.findRealWorldRadius(previousCircle.radius);
    
     // Calculate the overlap area between the existing circle and the new circle
    const partialOverlapAreaMM = this.calculatePartialOverlapArea(cen_to_cenMM, previousRadiusMM, currentRadiusMM);
    newCirclePartialOverlapArea = partialOverlapAreaMM ;
    const relatedOverlapArea = partialOverlapAreaMM ;
    newCircleRelatedOverlapArea = relatedOverlapArea;
  
   /*
    if (!partialOverlapAreaMM || partialOverlapAreaMM <= 0) {
    // Skip invalid or non-overlapping circles
      return;
    }
    */
    
    if(partialOverlapAreaMM > 0 || overlap_circles.length == 1){ //overlap_circles.length == 1 is to just area of first-circle
    
      //find partial-overlap area of new circle
      newCircleNonOverlapArea = Math.abs(currentCircleAreaMM - partialOverlapAreaMM);
      newCircleNonOverlapVolume = newCircleNonOverlapArea * this.fullLength;

      if(move == 0){  
        previousCircle.partialOverlapArea += partialOverlapAreaMM ;
        previousCircle.nonOverlapArea -= partialOverlapAreaMM ;
        //previousCircle.nonOverlapArea = Math.abs(previousCircle.area - previousCircle.partialOverlapArea); //previousCircle.partialOverlapArea is current and previous overlap area
        //alert("previousCircle.area " + previousCircle.area  + "previousCircle.partialOverlapArea " + previousCircle.partialOverlapArea + " previousCircle.nonOverlapArea " + previousCircle.nonOverlapArea);
      }else{ //move 1 is for increase/decrease and move
        //const diff_partial = Math.abs(previousCircle.partialOverlapArea - partialOverlapAreaMM);
        if(overlap_circles.length < 3 && previousCircle.circleId == 1){
          previousCircle.relatedOverlapArea = 0;
        }
        const partial_with_previousArea = Math.abs(previousCircle.partialOverlapArea - previousCircle.relatedOverlapArea);
        const diff_partial = Math.abs(partial_with_previousArea - relatedOverlapArea);
        if( relatedOverlapArea > partial_with_previousArea){
            previousCircle.nonOverlapArea -= diff_partial;
            previousCircle.partialOverlapArea += diff_partial;
        }else{
            previousCircle.nonOverlapArea += diff_partial;
            previousCircle.partialOverlapArea -= diff_partial;
        }

      }
      if(overlap_circles.length < 3 && previousCircle.circleId == 1){
          previousCircle.relatedOverlapArea = partialOverlapAreaMM;
      }
      
      previousCircle.nonOverlapVolume = previousCircle.nonOverlapArea * previousCircle.full_depth;
    
  // Add the new circle with its calculated properties
  if(push_no == 1){
      overlap_circles.push({
      x: currentX,
      y: currentY,
      xReal:xReal,
      yReal:yReal,
      circleId: currentCircleID,
      fromCircle: fromCricle,
      cen_to_cenMM: cen_to_cenMM_,
      radius: currentRadiusPixel,
      diameterMM: currentDiameterMM,
      area: currentCircleAreaMM,
      touch_depth: touch_depth,
      longest_depth: 0,
      full_depth: this.fullLength,
      relatedOverlapArea: newCircleRelatedOverlapArea,
      partialOverlapArea: newCirclePartialOverlapArea,
      prevNonOverlapArea: 0,
      nonOverlapArea: newCircleNonOverlapArea,
      nonOverlapVolume: newCircleNonOverlapVolume,
      touchX: currentX,
      touchY: currentY,
      baseLineY: 0
    });  // Ensure non-negative result
  }else{
    // Update the existing circle in place if `push_no` is 0
    //alert("currentRadiusMM "+ currentRadiusMM + " currentCircleAreaMM " + currentCircleAreaMM);
    for (let i = 0; i < overlap_circles.length; i++) {
      if (overlap_circles[i].x === currentX && overlap_circles[i].y === currentY) {
        overlap_circles[i].xReal = xReal;
        overlap_circles[i].yReal = yReal ;
        overlap_circles[i].cen_to_cenMM = cen_to_cenMM_;
        overlap_circles[i].diameterMM = currentDiameterMM;
        overlap_circles[i].area = currentCircleAreaMM;
        overlap_circles[i].relatedOverlapArea = newCircleRelatedOverlapArea;
        overlap_circles[i].partialOverlapArea = newCirclePartialOverlapArea;
        overlap_circles[i].nonOverlapArea = newCircleNonOverlapArea;
        overlap_circles[i].nonOverlapVolume = newCircleNonOverlapVolume;
        overlap_circles[i].radius = currentRadiusPixel; // Update the radius as well
        break;
      }//if
    }//for
  }//if(push_no == 0)

} //if if(partialOverlapAreaMM > 0)
  //}

}); //forEach


  //alert(" newCircleNonOverlapArea " + newCircleNonOverlapArea);

},  //calculateNonOverlapAreaMultiCircles

calculatePartialOverlapArea(this: any, cen_to_cenMM: number, r1: number, r2: number) {
  // No overlap if the distance between centers is larger than the sum of radii
  const add_radius = Math.abs(r1 + r2) ;
  //alert("add_radius " + add_radius);
  if (cen_to_cenMM >= add_radius) {
    //alert("not overlap");
    return 0;
  } else if (cen_to_cenMM <= Math.abs(r1 - r2)) {  //complete overlap
    // Complete overlap (one circle inside the other)
    //alert("Completely overlap");
    return 0;
  } else{// Partial overlap
    // Calculate the overlap area using the formula for two intersecting circles in mm²
    //alert("cen_to_cenMM " + cen_to_cenMM + " add_radius " + add_radius) ;
    //trianglesAreas (Heron formula)
    const sectorArea1 = r1 * r1 * Math.acos((cen_to_cenMM * cen_to_cenMM + r1 * r1 - r2 * r2) / (2 * cen_to_cenMM * r1));
    const sectorArea2 = r2 * r2 * Math.acos((cen_to_cenMM * cen_to_cenMM + r2 * r2 - r1 * r1) / (2 * cen_to_cenMM * r2));
    const trianglesAreas = 0.5 * Math.sqrt((-cen_to_cenMM + r1 + r2) * (cen_to_cenMM + r1 - r2) * (cen_to_cenMM - r1 + r2) * (cen_to_cenMM + r1 + r2));
    const partial_overlap_area = sectorArea1 + sectorArea2 - trianglesAreas;
    //alert("partial-overlap area" + partial_overlap_area);
    return partial_overlap_area;  // Return the overlap area in mm²
  }
},

selectDetectedCircle(this:any,num:number ){
     //   this.selectCircleMode = true;
        multi_circles = false;  //to close overlay-mode
        green_circle = true;
        CircleDetectionSetting.currentNum = num ;
        this.selectedCircleDetectionMode=num;
        this.startCircleDetection(); //detectCircle();
        this.calculateDiameter();
},
    
calculateDiameter(  this: any ){
  
  //this.avgLength = 400; //dummy
  //this.currentCircle.radius  = 30; //dummy
  if(this.avgLength){

    screen_width = this.videoWidth ;

    adjustedDynamicRadiusPixel = 0; //reset adjusted radius
    
    const LaserStartingPoint =  this.DiameterCorrectionValue.detectStartPoint ;
    //alert(LaserStartingPoint);
    if (screen_width ==0){ return ;}

    let destAngle = ( ( (this.currentCircle.radius  ) / screen_width  /*554.25*/ /*this.cameraOutput.width*/ ) * (hov) ) ;   //zzt updated 560 to this.cameraOutput.width
    touch_theta = destAngle ;
    if(destAngle <0){ return ; }

    //use in overlay image
    touch_depth = (  ( this.avgLength - this. calibrationA ) /this. calibrationB  ) + LaserStartingPoint  ; 
    this.touchLength =  touch_depth;
    //alert(this.touchLength);
    const rad = ( ( destAngle) * Math.PI / 180 ) ;

    //d: diameter
    //const d =   (((  ( this.avgLength - this. calibrationA ) /this. calibrationB  ) + LaserStartingPoint ) * Math.tan ( rad  ))*2 ;
    const d =   (touch_depth * Math.tan (rad))*2 ;
    if( d < 0 ){ return ;}
    this.diameter =   d * 1.05; // (d* this.DiameterCorrectionValue.coefficient) * / diameterCoefficient;
    if(this.diameter!==0){
        this.diameter =  this.diameter  + 2.5;
    }
    dynamicRadiusPixel = this.diameter; //to green_adjust
  
  }//if(this.avgLength)
     
},
verifyRegression(this: any, A: number, B: number, X2: number, Y2: number) {
    // Calculate X1 using the regression relationship
    const X1 = this.regressionData[0].X; // Already stored during regression
    const Y1 = A + (B * X1); // Calculate Y1 using X1

    // Verify correctness
    const calculatedY2 = A + (B * X2); // Recalculate Y2 using X2
    alert(`Verification Results:
        X1: ${X1}, Y1: ${Y1},
        Given Y2: ${Y2}, Calculated Y2: ${calculatedY2}`);
    
    if (Math.abs(Y2 - calculatedY2) < 0.0001) {
        console.log("The regression calculation is correct!");
    } else {
        console.log("There is an error in the regression calculation.");
    }
},
calculateRegressionAB(this: any) {
  // Fixed points
  /*
  const X1 = 30.0; // Diameter 1
  const Y1 = 165.6603325; // Distance 1

  const X2 = 20.0; // Diameter 2
  const Y2 = 144.6603325; // Distance 2
  */

  // Dynamic distance
  const dynamicY = this.touchLength;

  // Step 1: Calculate slope (B)
 // const B = (Y2 - Y1) / (X2 - X1);

  // Step 2: Calculate intercept (A)
  //const A = Y1 - B * X1;
  const dynamicX = 102.885 - (0.4762 * dynamicY);
  this.diameter = dynamicX;


  // Step 3: Calculate dynamic diameter (X) for the given Y
  //const dynamicX = (dynamicY - A) / B;

  // Save the calculated diameter
  this.baseLineY = dynamicX;

  // Return results
  //return { A, B, dynamicX };
},

xlsxFunc(this: any, cell: any, row: number, col: number) {
    if (this.viewState.selectCellMode) {
        if (!this.viewState.DateTimeCellSelected) {
            if (
                window.confirm(
                    "日時のセルを " +
                        this.exchangeToColName(col + 1) +
                        (row + 1) +
                        " に設定しますか？"
                )
            ) {
                this.selectedDateTimeCell.row = row;
                this.selectedDateTimeCell.col = col;
                this.viewState.DateTimeCellSelected = true;
                return;
            }
        } else if (!this.viewState.fullLengthCellSelected) {
            if (
                this.selectedDateTimeCell.row === row &&
                this.selectedDateTimeCell.col === col
            ) {
                return;
            }
            if (
                window.confirm(
                    "削孔長のセルを " +
                        this.exchangeToColName(col + 1) +
                        (row + 1) +
                        " に設定しますか？"
                )
            ) {
                this.selectedFullLengthCell.row = this.selectedDateTimeCell.row;
                this.selectedFullLengthCell.col = col;
                this.viewState.fullLengthCellSelected = true;
                return;
            }
        } else if (!this.viewState.diameterCellSelected) {
            if (
                this.selectedFullLengthCell.row === row &&
                this.selectedFullLengthCell.col === col
            ) {
                return;
            }
            if (
                window.confirm(
                    "削孔径のセルを " +
                        this.exchangeToColName(col + 1) +
                        (row + 1) +
                        " に設定しますか？"
                )
            ) {
                this.selectedDiameterCell.row = this.selectedDateTimeCell.row;
                this.selectedDiameterCell.col = col;
                this.viewState.diameterCellSelected = true;
                return;
            }
        } else if (!this.viewState.RealData1CellSelected) {
            if (
                this.selectedDiameterCell.row === row &&
                this.selectedDiameterCell.col === col
            ) {
                return;
            }
            if (
                window.confirm(
                    "一番大きいセンサ値のセルを " +
                        this.exchangeToColName(col + 1) +
                        (row + 1) +
                        " に設定しますか？"
                )
            ) {
                this.selectedRealData1Cell.row = this.selectedDateTimeCell.row;
                this.selectedRealData1Cell.col = col;
                this.viewState.RealData1CellSelected = true;
                return;
            }
        } else if (!this.viewState.RealData2CellSelected) {
            if (
                this.selectedRealData1Cell.row === row &&
                this.selectedRealData1Cell.col === col
            ) {
                return;
            }
            if (
                window.confirm(
                    "2番目に大きいセンサ値のセルを " +
                        this.exchangeToColName(col + 1) +
                        (row + 1) +
                        " に設定しますか？"
                )
            ) {
                this.selectedRealData2Cell.row = this.selectedDateTimeCell.row;
                this.selectedRealData2Cell.col = col;
                this.viewState.RealData2CellSelected = true;
                return;
            }
        } else if (!this.viewState.RealData3CellSelected && !this.isGpsUsed) {
            if (
                this.selectedRealData2Cell.row === row &&
                this.selectedRealData2Cell.col === col
            ) {
                return;
            }
            if (
                window.confirm(
                    "3番目に大きいセンサ値のセルを " +
                        this.exchangeToColName(col + 1) +
                        (row + 1) +
                        " に設定しますか？"
                )
            ) {
                this.selectedRealData3Cell.row = this.selectedDateTimeCell.row;
                this.selectedRealData3Cell.col = col;
                this.viewState.RealData3CellSelected = true;
                return;
            }
        } else if (!this.viewState.laserDistanceCellSelected && !this.isGpsUsed) {
            if (
                this.selectedRealData3Cell.row === row &&
                this.selectedRealData3Cell.col === col
            ) {
               // alert(this.selectedDiameterCell.row);
                return;
            }
            if (
                window.confirm(
                    "レーザー距離のセルを " +
                        this.exchangeToColName(col + 1) +
                        (row + 1) +
                        " に設定しますか？"
                )
            ) {
                this.selectedLaserDistanceCell.row = this.selectedDateTimeCell.row;
                this.selectedLaserDistanceCell.col = col;
                this.viewState.laserDistanceCellSelected = true;
                return;
            }
        } else if (!this.viewState.YValueCellSelected && !this.isGpsUsed) {
            if (
                this.selectedLaserDistanceCell.row === row &&
                this.selectedLaserDistanceCell.col === col
            ) {
                return;
            }
            if (
                window.confirm(
                    "回帰_Yのセルを " +
                        this.exchangeToColName(col + 1) +
                        (row + 1) +
                        " に設定しますか？"
                )
            ) {
                this.selectedYValueCell.row = this.selectedDateTimeCell.row;
                this.selectedYValueCell.col = col;
                this.viewState.YValueCellSelected = true;
                this.viewState.selectCellMode = false; // Exit cell selection mode
                return;
            }
        } else if (!this.viewState.gpsCellSelected && this.isGpsUsed) {
            if (
                this.selectedFullLengthCell.row === row &&
                this.selectedFullLengthCell.col === col
            ) {
                return;
            }
            if (
                window.confirm(
                    "位置情報のセルを " +
                        this.exchangeToColName(col + 1) +
                        (row + 1) +
                        " に設定しますか？"
                )
            ) {
                this.selectedGPSCell.row = this.selectedDateTimeCell.row;
                this.selectedGPSCell.col = col;
                this.viewState.gpsCellSelected = true;
                this.viewState.selectCellMode = false;
                return;
            }
        }
    }
},

  setXlsxFunc (this:any){
      
            this.spreadsheet.on('cell-selected', this.xlsxFunc );   
  },
  exportXlsx(this : any ){
           const filename = window.prompt( "ファイル名を入力して下さい。");
           if(filename == "" || filename == null ){ return ; }
           
           
           const new_wb = xtos( this.spreadsheet.getData());

          XLSX.writeFile(new_wb, filename+".xlsx");
       },
    async saveXlsx(this :any){
           if( this.filename == "" || this.filename == null ){ 
                 this.$emit('show-flash',{"message":"ファイル名がありません。","type": "warning"});          
                return ;
            }
           
           const new_wb = xtos( this.spreadsheet.getData());
              
            try{
               
            var wbout = XLSX.write(new_wb, {
                bookType:'xlsx',
                bookSST:true,
                type:'base64'
            });
        
         const r = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
          {data:wbout,filename: moment().format('YYYY/MM/DD/') +  this.filename  + ".xlsx"  });
   
          if(r.data.error==0){
              
               XLSX.writeFile(new_wb, this.filename+".xlsx");
                  this.$emit('show-flash',{"message":"ファイルを保存しました。","type": "success"});        
          } else {
               this.$emit('show-flash',{"message":"クラウドへのファイル保存に失敗しました。ネットワークを確認してください。","type": "danger"});  
              
               XLSX.writeFile(new_wb, this.filename+".xlsx");
        
                      
          }
          }catch(e :any ){
               this.$emit('show-flash',{"message":"クラウドへのファイルの保存に失敗しました。ネットワークを確認してください。","type": "danger"});      
              
                  XLSX.writeFile(new_wb, this.filename+".xlsx");      
          }
          
       },
        
  importXlsx(this :any,e:any){
           
      if (e.target instanceof HTMLInputElement) { 
        const file =  e.target.files[0];
        const reader : any = new FileReader();
        reader.onload = () => {
            
          const wb = XLSX.read(reader.result, { type: "binary" })
          this.xSpreadSheetData = stox(wb);

          this.SpreadSheetKey ++ ;
          this.xlsxUpdated = true ;
         // console.log(this.xSpreadSheetData);
          this.spreadsheet.loadData(this.xSpreadSheetData);
        }
         reader.readAsBinaryString(file);
      }  
  
       },   
        outsideClickEvent (this:{ viewState:any },e:any)  { 
            // alert(e.target.className);
              if(  e.target.className.indexOf( 'show-excel' ) ===  -1 ){  this. viewState. isExcelFileMenuShow = false;  }
          }
          ,selectCellMode( this:any ){
                alert("データを記入するセルを選択してください。\n日時\n削孔長\n削孔径\n一番大きいセンサ値\n二番目に大きいセンサの値\n三番目に大きいセンサの値\nレーザー距離\n回帰_Y\nの順に指定します。");

                // this.$emit('show-flash',{"message":"データを記入するセルを選択してください。","type": "success"});   
                this.showArea('Excel');
                this. viewState. isExcelFileMenuShow = false; 
                this.viewState.selectCellMode = true;   
                this.viewState.fullLengthCellSelected = false;
                this.viewState.diameterCellSelected = false;
                this.viewState.gpsCellSelected = (this.isGpsUsed) ? false:true;

                this.viewState.DateTimeCellSelected = false;
                this.viewState.RealData1CellSelected = false;
                this.viewState.RealData2CellSelected = false;
                this.viewState.RealData3CellSelected = false;
                //two-new columns
                this.viewState.laserDistanceCellSelected = false;
                this.viewState.YValueCellSelected = false;

                
          
       } ,
   async  writeFile(this:any, fileHandle:any, contents:any) {
  // writable作成
  const writable = await fileHandle.createWritable();

  // コンテンツを書き込む
  await writable.write(contents);
  // ファイル閉じる
  await writable.close();
    },
           
  async startMeasurement( this:any ){

          //...................................................
          //cover red-circle mode zzt
          multi_circles = false;
          overlap_circles = [];
          //clear if canvas was drawed
          let canvas = global_canvas;  // Assuming global_canvas is defined and available
          if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear the entire canvas
            }
          }
          global_canvas = null;

          //clear variables
          overlap_circles.splice(0, overlap_circles.length); //clear overlap_circles[]
          //set camera active mode
          this.isCaptureActive = false; // Mark as displaying a captured frame
          this.isCaptureDisabled = false; // Dim the button
          //...................................................
          

           if (  !this.filename || this.filename == ""){
           this.filename = window.prompt( "ファイル名を入力して下さい。");
           if ( !this.filename || this.filename == "" ){
            // this.$emit('show-flash',{"message":"ファイル名をご入力してください。","type": "warning"});      
               return ; 
           }
         }
        if ( this.isMeasuring) {
         if ( window.confirm("本当に計測を終了しますか？")){
              this.saveXlsx();
              this.removeCurrentData();
              this.wipeCurrentData();
              this.saveCurrentData();
              green_circle = false;
         } 
         return;
        }//if ( this.isMeasuring)
        
         
  //          /*   this.filehandle = await ( window as any ).showSaveFilePicker({ types: [{  description: "Text Files", accept: { "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"[".xlsx"],},},] }); await this.writeFile(this.fileHandle, ""); */
         
        /*   if( 
                (  this.selectedDiameterCell.row === -1 && this.selectedDiameterCell.col === -1 ) ||
                this.selectedFullLengthCell.row === -1, this.selectedFullLengthCell.col === -1 || 
                this.selectedGPSCell.row === -1, this.selectedGPSCell.col === -1
            ){

              */
 
                this.selectCellMode();

            //return ;
            //}
             this.isMeasuring = true ;
    
            await this.measurement();
            
            if(this.isMeasuring){
              if(this.selectedDetectionMode.includes('circle') ){
                //this.startCircleDetection();
              }
            }//if(this.isMeasuring)
        },
        async uploadBase64Image (this:any,base64img:any,filename:string){
        
        try{
           const base64string = base64img.split('base64,')[1];     
           const  r  = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
          {data: base64string ,filename:  filename }
            );
        
          if(r.data.error==0){
              
                  this.$emit('show-flash',{"message":"写真をアップロードしました。","type": "success"});
                  downloadBase64Image(base64img,filename);
          } else {
               
               this.$emit('show-flash',{"message":"写真のアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});          
               downloadBase64Image(base64img,filename);
          }

          } catch(e :any ){
               
               this.$emit('show-flash',{"message":"写真をアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});      
               
                  downloadBase64Image(base64img,filename);
          }
        },
        async measurement ( this : any ) {
          
            try{ if(  !( await this.serial1?.getInfoAsync()) ){ await this.serial1?.connect(); } } catch(er){ console.error(er); }
            try{ if( !( await this.serial2?.getInfoAsync()) ){ await this.serial2?.connect();} } catch(er){ console.error(er); }
            try{ if( !( await this.serial3?.getInfoAsync()) ){ await this.serial3?.connect();} } catch(er){ console.error(er); }
        
            try{
                                
                this.serialIntervalTaskFunc= setInterval( async ()=>  {
                       
                    if( this.isMeasurementStopped ){ return ; }
                    this.serial1?.setReaderLock();
                       
                    await this.serial1?.sendString("iSM\n");
                    var text1:any =  await this.serial1?.readString();  
                    
                    if( text1 !==""){
                    const arr1=convertSensorDataToArr( text1);
                    
                    for(let i = 0 ;  i < arr1.length ; i++){     
                         this.serial1Value = this.calibration[ this.calibration.use ] ( "serial1Value",  arr1[i] ); 
                    }
                    }
               
                    await this.serial1?.releaseReaderLock();
          
                    this.serial2?.setReaderLock();
                        
                    await this.serial2?.sendString("iSM\n");
                    var text2:any =  await this.serial2?.readString();  
                    if( text2 !==""){
                    const arr2 =convertSensorDataToArr( text2);
                    
                    for(let i = 0 ;  i < arr2.length ; i++){     
                         this.serial2Value = this.calibration[ this.calibration.use ] ( "serial2Value",  arr2[i] ); 
                    }
                    }

               
                    await this.serial2?.releaseReaderLock();
           
                    this.serial3?.setReaderLock();
                        
                    await this.serial3?.sendString("iSM\n");
                    var text3:any =  await this.serial3?.readString();  
                    if( text3 !==""){  
                    const arr3 =convertSensorDataToArr( text3);
                    
                    for(let i = 0 ;  i < arr3.length ; i++){     
                         this.serial3Value = this.calibration[ this.calibration.use ] ( "serial3Value",  arr3[i] ); 
                    }
                    }
                    await this.serial3?.releaseReaderLock();
    
                    const values = [  this.serial1Value * 1000 ,this.serial2Value*1000, this.serial3Value*1000 ].sort(
                        function (a, b) { return a - b }
                    );
           
                    const v1 =  values[0] ;
                    const v2 =  values[1] ;
                    var v3 = values[2];
                    this.realData1 = v1;
                    this.realData2 = v2;
                    this.realData3 = v3;

                    if (this.calibration. isLowerThanBothLengthChecked ){
                  
                        if (  excludeBothLengthMs  >=  v1 ){
                            this.avgLength = v1;
                            
                        }   
                        if (  excludeBothLengthMs  >=  v2 ){
                            this.avgLength = v2;
                        } else{
                             this.avgLength = ( ( v1 + v2)  /2 );
                        }  
                    } else {
                        
                        this.avgLength = ( ( v1 + v2)  /2 );
                    }
             
                    if(
                        v3 <= this.LengthCorrectionValue.center.thr 
                    ){
                
                        v3 = v3 +this.LengthCorrectionValue.center.add.above;
                    }else{
                
                        v3 = v3+ this.LengthCorrectionValue.center.add.below;
                    }
                     var fullLength = v3 - this.avgLength;
                    
                     if (this.calibrationCorrectionShortMeasurementsMillimetre != 0 &&
                         this.calibrationThresholdShortMeasurementsCentimeter >= fullLength ){
                          fullLength = fullLength -  this.calibrationCorrectionShortMeasurementsMillimetre /10 ;
                        }
                     this.fullLength = fullLength;
                     
                }  , this.measurementTimerMs );
                
               
              }catch(e){
                
              await this.serial1?.releaseReaderLock();
               await this.serial2?.releaseReaderLock();
               await this.serial3?.releaseReaderLock();
              }
        },
        
        undo(this:any){
          if (false === this.canBeUndone){return;}
          
          
          this.canBeUndone=false;
          const sheet = this.spreadsheet;
            
          this.selectedFullLengthCell.row  = this.selectedFullLengthCell.row  - 1 ;
              	sheet.cellText( 
                  this.selectedFullLengthCell.row , 
                  this.selectedFullLengthCell.col ,
                  ""
               	).reRender();      
           // this.selectedFullLengthCell.row  = this.selectedFullLengthCell.row  - 1 ;
          	
          	
          	if(this.enableDiameter){
            	this.selectedDiameterCell.row  = this.selectedDiameterCell.row  - 1 ;
              	sheet.cellText( 
                  this.selectedDiameterCell.row , 
                  this.selectedDiameterCell.col ,
                  ""
               	).reRender();      
            //	this.selectedDiameterCell.row  = this.selectedDiameterCell.row  - 1 ;
            }

          	this.selectedDateTimeCell.row= this.selectedDateTimeCell.row-1;
          	 sheet.cellText( 
                this.selectedDateTimeCell.row , 
                this.selectedDateTimeCell.col ,
               ""
              ).reRender();
            // this.selectedDateTimeCell.row= this.selectedDateTimeCell.row-1;
          	  
            this.selectedRealData1Cell.row =this.selectedRealData1Cell.row -1;
             sheet.cellText( 
                this.selectedRealData1Cell.row , 
                this.selectedRealData1Cell.col ,
                ""
              ).reRender();
            //this.selectedRealData1Cell.row =this.selectedRealData1Cell.row -1; 
            
            
               if(this.isGpsUsed){
          	this.selectedGPSCell.row  = this.selectedGPSCell.row  - 1 ;
              	sheet.cellText( 
                  this.selectedGPSCell.row , 
                  this.selectedGPSCell.col ,
                  ""
               	).reRender();      
           // this.selectedGPSCell.row  = this.selectedGPSCell.row  - 1 ;
          	}
          
          
            this.selectedRealData2Cell.row =this.selectedRealData2Cell.row -1;
            
             sheet.cellText( 
                this.selectedRealData2Cell.row , 
                this.selectedRealData2Cell.col  ,
                ""
               ).reRender();

           // this.selectedRealData2Cell.row =this.selectedRealData2Cell.row -1;
                        
            this.selectedRealData3Cell.row =this.selectedRealData3Cell.row -1;
            sheet.cellText( 
                      this.selectedRealData3Cell.row , 
                      this.selectedRealData3Cell.col  ,
                    ""
                    ).reRender();

           //tow-columns added
          // Populate laser-distance cell
          this.selectedLaserDistanceCell.row -= 1;
          sheet.cellText(
              this.selectedLaserDistanceCell.row,
              this.selectedLaserDistanceCell.col,
              "" // Clear the cell
          ).reRender();

          this.selectedYValueCell.row -= 1;
          sheet.cellText(
              this.selectedYValueCell.row,
              this.selectedYValueCell.col,
              "" // Clear the cell
          ).reRender();

        
            
    	  //  this.selectedRealData3Cell.row =this.selectedRealData3Cell.row -1;
			    this. recentlyAcquiredData.pop();
			this.saveCurrentData();
		
        },


        async stop ( this : any , param  :any ) {

          if(!this.isMeasuring){return;} //updated to save red-circle

          //to calculate regression
          //this.touchLength = 150; //dummy
         if (this.touchLength) {
            this.calculateRegressionAB();
          } else {
            alert("Laser Distance is missing. Cannot save.");
          }
          //........................................................
          this.canBeUndone=true;
          
          if (!this.filename ){
              this.$emit('show-flash',{"message":"計測を開始してください。","type": "warning"});          
              return;
          } 
          
          this.showLoadingView(20000);
          
          this.isMeasurementStopped =true;
          this.$refs.cameraSound.play();
          const basefilename =  moment().format('YYYY/MM/DD/') + "接写/" +  this.filename + "-" + moment().format('YYYY-MM-DDTHH:mm:ss') ;
          const filename =  basefilename + ".jpg";
          if(this.useSubCamera){
              this.uploadSubCameraImage(basefilename.replace("接写/","") );
           }
          this.enableDiameter = param.diameter ;
        
          const context:any = document.createElement('canvas').getContext('2d')
          //zzt
          if(!this.enableDiameter){
              this.cameraOutput .getContext("2d").clearRect(0, 0, this.cameraOutput.width, this.cameraOutput.height);
          }
             
          var lastdata :any = {};
             
    //try{
      
            const img1 = new Image();
            await new Promise((resolve, reject) => {
                img1.onload = () => resolve(img1);
                img1.onerror = (e) => reject(e);
                img1.src =  this.$refs.canvasOutput.toDataURL();
            })
                        
            const img2 = new Image();    
            await new Promise((resolve, reject) => {
                img2.onload = () => resolve(img2);
                img2.onerror = (e) => reject(e);
                img2.src =  this.$refs.canvas.toDataURL();
            })
            
            context.canvas.width = this.$refs.canvas.width;
            context.canvas.height = this.$refs.canvas.height;
            
            
            context.drawImage(img2,0,0)
            context.drawImage(img1,0,0)
            context.drawImage( this.$refs.denshiShoKokubanCanvas , 0, 0);
            var base64img = context.canvas.toDataURL('image/jpeg', 1.0);
            
            if(this.isGpsUsed){
              if ( this.gps.lat && this.gps.lon ){
                const exifObj = piexif.load(base64img);
        
                exifObj.GPS[piexif.GPSIFD.GPSLatitude] =  degToDmsRational( this.gps.lat );
                exifObj.GPS[piexif.GPSIFD.GPSLatitudeRef] =  "N";
                exifObj.GPS[piexif.GPSIFD.GPSLongitude] =  degToDmsRational( this.gps.lon );
                exifObj.GPS[piexif.GPSIFD.GPSLongitudeRef] =  "W";

                const exifbytes = piexif.dump(exifObj);
                base64img = piexif.insert(exifbytes, base64img);
              } 
            }
            
            const base64string = base64img.split('base64,')[1];       
            
            
            await this.uploadBase64Image (base64img,filename);
        //   const  r  = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
       //   {data: base64string ,filename:  filename }
       //     );
        /*
          if(r.data.error==0){
              
                  this.$emit('show-flash',{"message":"写真をアップロードしました。","type": "success"});
                  downloadBase64Image(base64img,filename);
          } else {
               
               this.$emit('show-flash',{"message":"写真のアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});          
               downloadBase64Image(base64img,filename);
          }

          }catch(e :any ){
               
               this.$emit('show-flash',{"message":"写真をアップロードに失敗しました。ネットワークを確認してください。","type": "danger"});      
               
                  downloadBase64Image(base64img,filename);
          }
      */
      
      /*
           lastdata.img = {  name : filename , base64 :  base64img };
        */    
            const sheet = this.spreadsheet;
            
             sheet.cellText( 
                this.selectedFullLengthCell.row , 
                this.selectedFullLengthCell.col ,
                this.fullLength  // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();
            
            if (this.nonOverlapCircleAreaEnabled){
            sheet.cellText(this.selectedDiameterCell.row, 
            this.selectedDiameterCell.col , 
            this.nonOverlapCircleArea).reRender(); 
            }
            if(this.enableDiameter){
              sheet.cellText( 
                  this.selectedDiameterCell.row , 
                  this.selectedDiameterCell.col ,
                  this.diameter  //  this.ceilDemicalPoint ( this.diameter * 0.1 ,1 )
               ).reRender();      
            }

             sheet.cellText( 
                this.selectedDateTimeCell.row , 
                this.selectedDateTimeCell.col ,
                new Date().toLocaleString('ja-JP', {
                timeZone: 'Asia/Tokyo',
              }) // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();
            
              sheet.cellText( 
                this.selectedRealData1Cell.row , 
                this.selectedRealData1Cell.col ,
                this.realData1  // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();
            
              sheet.cellText( 
                this.selectedRealData2Cell.row , 
                this.selectedRealData2Cell.col  ,
                this.realData2  // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();

                sheet.cellText( 
                this.selectedRealData3Cell.row , 
                this.selectedRealData3Cell.col  ,
                this.realData3 // this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 )
              ).reRender();

              //append new columns
              //alert("this.realData3 " + this.realData3);
             //console.log("====");
             //console.log(this.touchLength);
             //console.log(this.baseLineY); 

              if (!this.touchLength || !this.baseLineY) {
                  console.error("Data for Laser Distance or Y Value is missing.");
                  return;
              }
              sheet.cellText(
                  this.selectedLaserDistanceCell.row,
                  this.selectedLaserDistanceCell.col,
                  this.touchLength
              ).reRender();

              sheet.cellText(
                  this.selectedYValueCell.row,
                  this.selectedYValueCell.col,
                  this.baseLineY
              ).reRender();
            
            if(this.isGpsUsed){
            sheet.cellText( this.selectedGPSCell.row , this.selectedGPSCell.col ,
                 this.gps.lat 
            ).reRender();    
             sheet.cellText( this.selectedGPSCell.row , this.selectedGPSCell.col+1 ,
               this.gps.lon
            ).reRender();      
            }
            
            this.selectedFullLengthCell.row = this.selectedFullLengthCell.row + 1 ;
            this.selectedDiameterCell.row  = this.selectedDiameterCell.row  + 1 ;
            this.selectedDateTimeCell.row= this.selectedDateTimeCell.row+1;
            this.selectedRealData1Cell.row =this.selectedRealData1Cell.row +1;
            this.selectedRealData2Cell.row =this.selectedRealData2Cell.row +1;
            this.selectedRealData3Cell.row =this.selectedRealData3Cell.row +1;
            //tow-columns
            this.selectedLaserDistanceCell.row =this.selectedLaserDistanceCell.row +1;
            this.selectedYValueCell.row =this.selectedYValueCell.row +1;
            
            lastdata.fullLength = { col :  this.selectedFullLengthCell.col , row : this.selectedFullLengthCell.row ,  data : this.fullLength  } as any;
              lastdata.diameter = {  col :  this.selectedDiameterCell.col ,  row :   this.selectedDiameterCell.row ,  data : this.diameter   } as any;
              
              if(this.nonOverlapCircleAreaEnabled){
              lastdata.nonOverlapCircleArea = { col: this.selectedDiameterCell.col, row: this.selectedDiameterCell.row, data: this.nonOverlapCircleArea } as any;
              }
            if(this.isGpsUsed){
            
            this.selectedGPSCell.row  = this.selectedGPSCell.row  + 1 ;   
            
              lastdata.gps = {} as any ;
              lastdata.gps.lat  = {  col :  this.selectedGPSCell.col ,  row :   this.selectedGPSCell.row ,  data :  this.gps.lat    } as any;
              lastdata.gps.lat  = {  col :  this.selectedGPSCell.col ,  row :   this.selectedGPSCell.row ,  data :  this.gps.lon    } as any;
            }

              if (this. recentlyAcquiredDataMax <= this.recentlyAcquiredData.length-1 ){
                  this.recentlyAcquiredData.splice(0, 1) ;
              }   
             this.recentlyAcquiredData.push(lastdata);
              
            //  this.recentlyAcquiredData.push(lastdata);
              
            // this.acquiredData.push(lastdata);
              
          //   await this.saveXlsx();   
             this.isMeasurementStopped =false;
             // this.showArea('Excel');
             //alert("Spreadsheet Data:"+ this.spreadsheet.getData());

            this.xSpreadSheetData= this.spreadsheet.getData();
            this.saveCurrentData();
             
          this.isLoading=false;
          
          //.................................................
          //clear multi_circles canvas and data
          let canvas = global_canvas;  // Assuming global_canvas is defined and available
          if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear the entire canvas
            }
          }
          global_canvas = null;
          //reactive capture mode
          this.isCaptureActive = false; // Mark as displaying a captured frame
          this.isCaptureDisabled = false; // Dim the button

          overlap_circles.splice(0, overlap_circles.length); //clear list
          //.................................................

          } //async stop() 
    }
    
}

